import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import BannerSection from "../../components/helpCenter/BannerSection";
import FAQSection from "../../components/helpCenter/FAQSection";
import StillHaveQuestion from "../../components/helpCenter/StillHaveQuestion";
import "./HelpCenter.scss";

const HelpCenter = () => (
    <>
        <Header type="LoggedIn" />
        <div className="help-center-wrapper">
            <BannerSection />
            <FAQSection />
            <StillHaveQuestion />
        </div>
        <Footer />
    </>
);

export default HelpCenter;
