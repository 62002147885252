import { useTranslation } from "react-i18next";
import { ContentCollection } from "../../types/content";
import { ArticleCardList } from "../common";
import { Heading, HorizontalLine } from "../elements";

interface PickedForYouProps {
    articleCollection: ContentCollection | null;
    loading: boolean;
}

const PickedForYou = ({ articleCollection, loading }: PickedForYouProps) => {
    const { t } = useTranslation();

    return articleCollection?.meta.pagination.total ? (
        <div className="picked-for-you-wrapper">
            <Heading level="h5">{t("LearnWithHubert.PickedForMe")}</Heading>
            <HorizontalLine weight="semi-dark" />
            <ArticleCardList
                articles={articleCollection?.contents ?? null}
                wrapperClass="grid-items-wrapper"
                loading={loading}
            />
        </div>
    ) : null;
};

export default PickedForYou;
