import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Heading, Modal, TabSelector, Text } from "../../../elements";
import PossibleOutcomes from "./PossibleOutcomes";
import RecruitmentProcess from "./RecruitmentProcess";

interface HowHubertScoreModalProps {
    isOpen: boolean;
    close: () => void;
}

const HowHubertScoreModal = ({ isOpen, close }: HowHubertScoreModalProps) => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState("possibleOutcomes");

    const tabs = [
        {
            textKey: "Interview.HowHubertScoresInterviews.Tabs_PossibleOutcomes",
            value: "possibleOutcomes",
        },
        {
            textKey: "Interview.HowHubertScoresInterviews.Tabs_RecruitmentProcess",
            value: "recruitmentProcess",
        },
    ];

    return (
        <Modal isOpen={isOpen} close={close} className="how-hubert-scores-modal-popup" large>
            <Heading level="h3" light className="text-center mb-6">
                {t("Interview.HowHubertScoresInterviews.Title")}
            </Heading>
            <Text>{t("Interview.HowHubertScoresInterviews.Description")}</Text>
            <TabSelector tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            {selectedTab === "possibleOutcomes" ? <PossibleOutcomes /> : <RecruitmentProcess />}
            <div className="text-center">
                <Button secondary onClick={close}>
                    {t("Interview.HowHubertScoresInterviews.ButtonText_Close")}
                </Button>
            </div>
        </Modal>
    );
};

export default HowHubertScoreModal;
