import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import useBreakpoint from "use-breakpoint";
import { JobPosting } from "../../../types/jobPosting";
import { Breakpoint, BREAKPOINTS } from "../../../utils/constants/breakpoints";
import { Alert } from "../../elements";
import SkeletonLoader from "../../elements/SkeletonLoader";
import CarouselArrow from "./CarouselArrow";
import JobPostingCard from "./JobPostingCard";

type JobPostingsNotLoadedState = {
    loading: true;
    error: undefined;
    jobPostings: undefined;
};

type JobPostingsLoadedState = {
    loading: false;
    error: undefined;
    jobPostings: JobPosting[];
};

type JobPostingsFailedToLoadState = {
    loading: false;
    error: unknown;
    jobPostings: undefined;
};

type JobPostingsListProps = JobPostingsNotLoadedState | JobPostingsLoadedState | JobPostingsFailedToLoadState;

function isJobPostingsLoaded(props: unknown): props is JobPostingsLoadedState {
    return props instanceof Object && "jobPostings" in props && Array.isArray(props.jobPostings);
}

function isJobPostingsFailedToLoadState(props: unknown): props is JobPostingsFailedToLoadState {
    return (
        props instanceof Object &&
        "loading" in props &&
        !props.loading &&
        "error" in props &&
        (props.error ? true : false)
    );
}

const NoJobPostings = () => {
    const { t } = useTranslation();

    return <Alert type="info">{t("Common.LatestJobPostings.NoJobPostings")}</Alert>;
};

const CAROUSEL_RESPONSIVENESS_SETTINGS = {
    SM_SCREEN: {
        breakpoint: BREAKPOINTS.sm, // upto SM screens
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
        },
    },

    LG_SCREEN: {
        breakpoint: BREAKPOINTS.lg, // upto LG screens
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
        },
    },

    DEFAULT: {
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
        },
    },
};

function getSlideCountToShow(breakpoint: Breakpoint) {
    if (breakpoint === "sm") {
        return CAROUSEL_RESPONSIVENESS_SETTINGS.SM_SCREEN.settings.slidesToShow;
    }

    if (breakpoint === "md") {
        return CAROUSEL_RESPONSIVENESS_SETTINGS.LG_SCREEN.settings.slidesToShow;
    }

    return CAROUSEL_RESPONSIVENESS_SETTINGS.LG_SCREEN.settings.slidesToShow;
}

const JobPostingsList = (props: JobPostingsListProps) => {
    const { breakpoint } = useBreakpoint(BREAKPOINTS, "lg");
    const noOfSlidesToShow = getSlideCountToShow(breakpoint);

    if (!isJobPostingsLoaded(props)) {
        if (isJobPostingsFailedToLoadState(props)) {
            return <NoJobPostings />;
        }

        return <SkeletonLoader className="skeleton-loading-element" count={noOfSlidesToShow} height="190px" />;
    }

    const { jobPostings } = props;

    const mapJobPostingToCard = (jobPosting: JobPosting) => (
        <JobPostingCard key={jobPosting.url} jobPosting={jobPosting} />
    );

    const jobPostingCards = jobPostings.map(mapJobPostingToCard);

    if (jobPostingCards.length < 1) {
        return <NoJobPostings />;
    }

    const shouldInfiniteScroll =
        jobPostingCards.length > CAROUSEL_RESPONSIVENESS_SETTINGS.DEFAULT.settings.slidesToShow;

    return (
        <Slider
            arrows
            infinite={shouldInfiniteScroll}
            speed={500}
            initialSlide={0}
            slidesToShow={CAROUSEL_RESPONSIVENESS_SETTINGS.DEFAULT.settings.slidesToShow}
            slidesToScroll={CAROUSEL_RESPONSIVENESS_SETTINGS.DEFAULT.settings.slidesToShow}
            className="job-postings-carousel"
            prevArrow={<CarouselArrow left />}
            nextArrow={<CarouselArrow right />}
            responsive={[CAROUSEL_RESPONSIVENESS_SETTINGS.SM_SCREEN, CAROUSEL_RESPONSIVENESS_SETTINGS.LG_SCREEN]}
        >
            {jobPostingCards}
        </Slider>
    );
};

export default JobPostingsList;
