import { gql, useMutation, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { toast } from "react-toastify";
import MiniLogoImage from "../../../assets/images/hubert-logo-mini.png";
import { Heading, LargeAlert } from "../../../components/elements";
import { LargeAlertProps } from "../../../components/elements/LargeAlert";
import Header from "../../../components/header/Header";
import LoginForm from "../../../components/login/LoginForm";
import SocialMediaLogin from "../../../components/login/SocialMediaLogin";
import { BenefitsBox } from "../../../components/registration";
import { PageRoute, PageState, ProviderId } from "../../../constants";
import { deserialize } from "../../../hooks/useDataSerializer";
import { PreInterviewCandidate } from "../../../pages/instantJobApply/PreInstantApply";
import "./Login.scss";

export const useLoginParams = (
    searchParams: URLSearchParams,
): {
    candidate?: PreInterviewCandidate;
    job?: { id: string; title: string };
    redirectUrl?: URL;
} => {
    const rawRedirectUrl = searchParams.get("redirectUrl");
    const redirectUrl = useMemo(() => (rawRedirectUrl ? new URL(rawRedirectUrl) : undefined), [rawRedirectUrl]);
    const rawJob = searchParams.get("job") ?? redirectUrl?.searchParams.get("job");
    const rawCandidate = searchParams.get("candidate") ?? redirectUrl?.searchParams.get("candidate");
    const rawEmail = searchParams.get("email") ?? "";

    return useMemo(() => {
        return {
            job: rawJob ? deserialize<{ id: string; title: string }>(rawJob) : undefined,
            candidate: rawCandidate ? deserialize<PreInterviewCandidate>(rawCandidate) : { email: rawEmail },
            redirectUrl,
        };
    }, [rawJob, rawCandidate, rawEmail, redirectUrl]);
};

export const getPostInterviewUrl = () =>
    new URL(`${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.POST_INTERVIEW}`, window.location.origin);

type LoginHintResponse = {
    loginHint: {
        found: boolean;
        providerData: { providerId: string }[];
    };
};

export const useReuseToken = () => {
    const [getReuseToken] = useMutation<{
        viewerCreateReuseToken: { token: string; user: string };
    }>(gql`
        mutation getReuseToken($jobId: String!) {
            viewerCreateReuseToken(jobId: $jobId) {
                token
                user
            }
        }
    `);
    return useMemo(
        () => ({
            async appendReuseParams(jobId: string, url: URL) {
                const reuse = await getReuseToken({ variables: { jobId } });
                if (reuse.data) {
                    url.searchParams.set("reuse", reuse.data.viewerCreateReuseToken.token);
                    url.searchParams.set("user", reuse.data.viewerCreateReuseToken.user);
                }
            },
        }),
        [getReuseToken],
    );
};

const RealTimeScreeningLogin = () => {
    const { t } = useTranslation();

    const [searchParams] = useSearchParams();

    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: "",
        subtitle: "",
        type: "info",
    });

    const { job, candidate, redirectUrl } = useLoginParams(searchParams);

    const candidateEmail = candidate?.email;

    const { data: loginHintData, loading: loginHintLoading } = useQuery<LoginHintResponse>(
        gql`
            query _hint($email: String!) {
                loginHint(email: $email) {
                    found
                    providerData {
                        providerId
                    }
                }
            }
        `,
        { variables: { email: candidateEmail } },
    );

    const { appendReuseParams } = useReuseToken();

    const checkSignInProvider = (selectedProvider: string): boolean => {
        const providerData = loginHintData?.loginHint.providerData ?? candidate?.providerData;
        if (!providerData || providerData.length === 0) {
            toast.info<string>("Unsupported provider");
            return false;
        }

        const found = providerData.find((x) => x.providerId === selectedProvider);

        if (found) {
            return true;
        }

        switch (providerData[0].providerId) {
            case ProviderId.PASSWORD:
                toast.info<string>("Please sign in using Password.");
                break;
            case ProviderId.GOOGLE:
                toast.info<string>("Please sign in using Google.");
                break;
            default:
                toast.info<string>("Unsupported provider");
                break;
        }

        return false;
    };

    const redirectWithoutSignIn = () => {
        if (!redirectUrl) throw new Error("redirectUrl missing");

        const postInterviewUrl = getPostInterviewUrl();
        postInterviewUrl.searchParams.set("state", PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN);

        const interviewRedirect = new URL(redirectUrl);
        interviewRedirect.searchParams.append("redirectUrl", postInterviewUrl.toString());

        window.location.replace(interviewRedirect.toString());
    };

    const postLoginEvent = async (token?: string) => {
        if (redirectUrl && candidateEmail) {
            const postInterviewUrl = getPostInterviewUrl();
            postInterviewUrl.searchParams.append("email", candidateEmail);

            const tokenAddedRedirectUrl = new URL(redirectUrl);
            if (token) tokenAddedRedirectUrl.searchParams.append("token", token);

            await appendReuseParams(job!.id, tokenAddedRedirectUrl);

            tokenAddedRedirectUrl.searchParams.set("redirectUrl", postInterviewUrl.toString());

            window.location.replace(tokenAddedRedirectUrl.toString());
        }
    };

    const loginOptions = [];
    if (loginHintLoading) {
        loginOptions.push(<p>Loading...</p>);
    } else {
        const providerData = loginHintData?.loginHint?.providerData ?? [];
        if (providerData.find((x) => x.providerId === "password")) {
            loginOptions.push(
                <LoginForm
                    setShowAlert={setShowAlert}
                    preLoadedEmail={candidateEmail}
                    state={PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN}
                    validateProvider={checkSignInProvider}
                    postLoginEvent={postLoginEvent}
                />,
            );
        }

        if (providerData.find((x) => x.providerId !== "password")) {
            if (loginOptions.length > 1)
                loginOptions.push(
                    <div className="or-section">
                        <div className="or-seperator-line" />
                        <div className="or-text">{t("Login.OrSeparator")}</div>
                    </div>,
                );
            else loginOptions.push(<div style={{ minHeight: "2rem" }} />);
            loginOptions.push(
                <SocialMediaLogin
                    state={PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN}
                    validateProvider={checkSignInProvider}
                    preLoadedEmail={candidateEmail}
                />,
            );
        }
    }

    return (
        <>
            <Header type="SignInInviteEmail" interviewRedirect={redirectWithoutSignIn} />
            <div className="login-invite-email-wrapper">
                <div className="left-section">
                    <Heading level="h2" light>
                        {job?.title}
                    </Heading>
                    <Heading level="h4">{t("LoginInviteEmail.Subtitle")}</Heading>
                    <BenefitsBox />
                </div>
                <div className="right-section">
                    <img className="hubert-logo-mini" src={MiniLogoImage} alt="Hubert logo mini" />
                    <Heading level="h4">{t("Login.LoginTitle")}</Heading>
                    {showAlert.title ? (
                        <LargeAlert title={showAlert.title} subtitle={showAlert.subtitle} type={showAlert.type} />
                    ) : (
                        <div>{loginOptions}</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default RealTimeScreeningLogin;
