import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../utils/cookieHandlers";
import { TabSelector } from "../elements";
import FAQItem from "./FAQItem";
import { tabs } from "./textKeyArrays";

type FAQTexts = {
    AboutHubertInterview: { q: string; a: string }[];
    TechnicalSupport: { q: string; a: string }[];
};

const FAQSection = () => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(tabs[0].value);
    const [shownTexts, setShownTexts] = useState<Array<{ q: string; a: string }>>([]);
    const texts = t("HelpCenter.Q&ATexts", {
        returnObjects: true,
    }) as FAQTexts;
    const language = getCookie("language");

    useEffect(() => {
        if (selectedTab === "aboutHubertInterview") {
            setShownTexts(texts.AboutHubertInterview);
        } else if (selectedTab === "technicalSupport") {
            setShownTexts(texts.TechnicalSupport);
        } else {
            setShownTexts([...texts.AboutHubertInterview, ...texts.TechnicalSupport]);
        }
    }, [selectedTab, language]);

    return (
        <div className="faq-section-wrapper">
            <div className="tab-selector-custom-wrapper">
                <TabSelector tabs={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} hugContent />
            </div>
            <div className="content-wrapper">
                {shownTexts.map((faq) => (
                    <FAQItem key={faq.q} questionText={faq.q} answerText={faq.a} />
                ))}
            </div>
        </div>
    );
};

export default FAQSection;
