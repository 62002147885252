import { useState } from "react";
import { useTranslation } from "react-i18next";
import MiniLogoImage from "../../assets/images/hubert-logo-mini.png";
import { Heading, LargeAlert } from "../../components/elements";
import { LargeAlertProps } from "../../components/elements/LargeAlert";
import { BenefitsBox, RegistrationInviteEmailForm, SocialMediaRegistration } from "../../components/registration";
import { TPageState } from "../../constants";
import { Invitation } from "../../types/invitation";
import "./InviteEmailSignUp.scss";
import { RegistrationOrLine } from "./RegistrationOrLine";

interface InviteEmailSignUpProps {
    interviewCompleted?: boolean;
    invitation?: Invitation | null;
    state: TPageState;
}

const InviteEmailSignUp = ({ interviewCompleted = false, invitation, state }: InviteEmailSignUpProps) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: "",
        subtitle: "",
        type: "info",
    });

    return (
        <div className="registration-invite-email-wrapper">
            <div className="left-section">
                <Heading level="h2">{invitation?.job?.title}</Heading>
                {interviewCompleted ? (
                    <Heading level="h4" light>
                        {t("RegistrationInviteEmail.Subtitle_AfterInterviewCompleted")}
                    </Heading>
                ) : (
                    <Heading level="h5" light>
                        {t("RegistrationInviteEmail.Subtitle_BeforeInterviewCompleted")}
                    </Heading>
                )}
                <BenefitsBox />
            </div>
            <div className="right-section">
                <img className="hubert-logo-mini" src={MiniLogoImage} alt="Hubert logo mini" />
                <Heading level="h4">{t("Registration.RegistrationTitle")}</Heading>
                {showAlert.title ? (
                    <LargeAlert title={showAlert.title} subtitle={showAlert.subtitle} type={showAlert.type} />
                ) : (
                    <div>
                        <RegistrationInviteEmailForm
                            setShowAlert={setShowAlert}
                            invitation={invitation}
                            state={state}
                        />
                        <RegistrationOrLine />
                        <SocialMediaRegistration invitation={invitation} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InviteEmailSignUp;
