import { useState } from "react";
import { useTranslation } from "react-i18next";
import MiniLogoImage from "../../assets/images/hubert-logo-mini.png";
import Illustration from "../../assets/images/illustration-3.png";
import { Heading } from "../../components/elements";
import LargeAlert, { LargeAlertProps } from "../../components/elements/LargeAlert";
import ForgotPasswordForm from "../../components/forgotPassword/ForgotPasswordForm";
import Header from "../../components/header/Header";
import "./ForgotPassword.scss";

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: "",
        subtitle: "",
        type: "info",
    });

    return (
        <>
            <Header type="Login" />
            <div className="forgot-password-wrapper">
                <div className="left-section">
                    <Heading level="h2">{t("ForgotPassword.ResetYourPassword.MainTitle")}</Heading>
                    <Heading level="h4" light>
                        {t("ForgotPassword.ResetYourPassword.Subtitle")}
                    </Heading>
                    <img className="bg-image" src={Illustration} alt="Illustration" />
                </div>
                <div className="right-section">
                    <img className="hubert-logo-mini" src={MiniLogoImage} alt="Hubert logo mini" />
                    <Heading level="h4">{t("ForgotPassword.ResetYourPassword.FormSectionTitle")}</Heading>
                    {showAlert.title ? (
                        <LargeAlert title={showAlert.title} subtitle={showAlert.subtitle} type={showAlert.type} />
                    ) : (
                        <div>
                            <ForgotPasswordForm setShowAlert={setShowAlert} />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
