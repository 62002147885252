import { useMutation } from "@apollo/client";
import { jwtDecode } from "jwt-decode";
import { useMemo } from "react";
import client from "../../behavior/apolloClient";
import { BooleanResponse } from "../../behavior/graphTypes/commonResponse";
import { SIGNOUT_USER } from "../../behavior/mutations/auth.mutation";
import { clearTokenFromStorage } from "../../behavior/security/auth.service";
import useReadLocalStorage from "../../helpers/hooks/useReadLocalStorage";
import { HubertUserJWTModel } from "../../types/authentication";

const getSessionToken = (rawToken: string | null) => {
    if (!rawToken || rawToken === "") return null;
    return {
        rawToken,
        user: rawToken ? jwtDecode<HubertUserJWTModel>(rawToken) : undefined,
    };
};

export const useMyHubertSession = () => {
    const rawToken = useReadLocalStorage<string>("candidate-hub-token", { parse: false });
    const [logoutUser] = useMutation<BooleanResponse>(SIGNOUT_USER);
    return useMemo(() => {
        let currentToken = getSessionToken(rawToken);
        let activeLogout: Promise<boolean> | null;
        return {
            get sessionToken() {
                return currentToken;
            },
            async logoutUser() {
                if (!activeLogout) {
                    activeLogout = new Promise<boolean>((resolve, _reject) => {
                        if (!currentToken) return false;
                        currentToken = null;
                        Promise.all([logoutUser(), client.clearStore()]).then(() => {
                            clearTokenFromStorage();
                            resolve(true);
                        });
                    });
                }

                try {
                    return await activeLogout;
                } finally {
                    activeLogout = null;
                }
            },
        };
    }, [rawToken, logoutUser]);
};
