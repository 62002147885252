import { MouseEventHandler } from "react";
import CarouselNextArrow from "../../../assets/images/carousel-next-icon.svg";
import CarouselPrevArrow from "../../../assets/images/carousel-prev-icon.svg";

type CarouselLeftArrow = {
    className?: string;
    style?: object;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    left: true;
};

type CarouselRightArrow = {
    className?: string;
    style?: object;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    right: true;
};

type CarouselArrowProps = CarouselLeftArrow | CarouselRightArrow;

const isLeftArrow = (props: unknown): props is CarouselLeftArrow => (props as CarouselLeftArrow).left;

const isRightArrow = (props: unknown): props is CarouselRightArrow => (props as CarouselRightArrow).right;

const CarouselArrow = (props: CarouselArrowProps) => {
    const { className, style, onClick } = props;

    const renderArrow = () => {
        if (isLeftArrow(props)) {
            return <img src={CarouselPrevArrow} className="slick-arrow slick-next" />;
        }

        return isRightArrow(props) ? <img src={CarouselNextArrow} className="slick-arrow slick-prev" /> : null;
    };

    return (
        <div className={className} style={style}>
            <button type="button" onClick={onClick}>
                {renderArrow()}
            </button>
        </div>
    );
};

export default CarouselArrow;
