import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import GoogleIcon from "../../assets/images/google-logo.png";
import { PageState, TPageState } from "../../constants";
import { LoginStateWithAction } from "../../types/authentication";
import { Invitation } from "../../types/invitation";
import { encodeParam, generateGoogleSignInUrl } from "../../utils/urlUtility";
import { Button } from "../elements";

interface SocialMediaRegistrationProps {
    invitation?: Invitation | null;
}

const SocialMediaRegistration = ({ invitation }: SocialMediaRegistrationProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const postInvitationStates = [
        PageState.INVITATION_POST_INTERVIEW_SIGN_UP,
        PageState.INVITATION_POST_INTERVIEW_SIGN_IN,
    ];
    const postJobApplyStates = [PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP, PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN];

    const extractToken = () => {
        return searchParams.get("token");
    };

    const googleSignUp = async () => {
        const pageState = searchParams.get("state");
        const email = searchParams.get("email");

        let loginState: LoginStateWithAction = { action: PageState.SIGN_UP };
        const token = extractToken();
        if (pageState === PageState.INVITATION_SIGN_UP) {
            loginState = {
                action: pageState,
                ...(token && { token }),
                invitation,
            };
        } else if (postJobApplyStates.find((x) => x === pageState)) {
            if (email)
                loginState = {
                    action: pageState as TPageState,
                    email,
                };
        } else if (postInvitationStates.find((x) => x === pageState)) {
            loginState = {
                action: pageState as TPageState,
                invitation,
                ...(token && { token }),
            };
        }

        const encodedGoogleSignUpState = encodeParam(loginState);
        const googleSignInUrl = generateGoogleSignInUrl(encodedGoogleSignUpState);

        if (postInvitationStates.find((x) => x === pageState) && invitation) {
            googleSignInUrl.searchParams.append("login_hint", invitation.invitee.email);
        } else if (postJobApplyStates.find((x) => x === pageState) && email) {
            googleSignInUrl.searchParams.append("login_hint", email);
        }

        window.location.replace(googleSignInUrl);
    };

    return (
        <div className="social-media-registration-wrapper">
            <Button secondary fullWidth className="mb-4" imgIcon={GoogleIcon} onClick={googleSignUp}>
                {t("Registration.ButtonText_SignUpWithGoogle")}
            </Button>
        </div>
    );
};

export default SocialMediaRegistration;
