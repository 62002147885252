import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_SHALLOW_CONTENTS } from "../../behavior/queries/content.query";
import { TabSelector } from "../../components/elements";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import BannerSection from "../../components/learnWithHubert/BannerSection";
import CareerGuides from "../../components/learnWithHubert/CareerGuides";
import InterviewGuides from "../../components/learnWithHubert/InterviewGuides";
import NewestOnTheFeed from "../../components/learnWithHubert/NewestOnTheFeed";
import PickedForYou from "../../components/learnWithHubert/PickedForYou";
import { ContentCollection } from "../../types/content";
import "./LearnWithHubert.scss";

const LearnWithHubert = () => {
    const [selectedTab, setSelectedTab] = useState("yourFeed");
    const { data, loading } = useQuery<{ contentCollection: ContentCollection }>(GET_SHALLOW_CONTENTS, {
        variables: {
            candidateFilterInput: {
                tags: [],
                optionalTags: [],
                pagination: {
                    limit: 12,
                    page: 1,
                },
            },
        },
        fetchPolicy: "no-cache",
    });

    const tabs = [
        {
            textKey: "LearnWithHubert.TabSelector.MyFeed",
            value: "yourFeed",
        },
        {
            textKey: "LearnWithHubert.TabSelector.InterviewGuides",
            value: "interviewGuides",
        },
        {
            textKey: "LearnWithHubert.TabSelector.CareerGuides",
            value: "careerGuides",
        },
    ];

    const renderComponents = () => {
        if (selectedTab === "yourFeed" && data?.contentCollection) {
            return (
                <>
                    <NewestOnTheFeed article={data?.contentCollection.contents.at(0) ?? null} />
                    <PickedForYou articleCollection={data?.contentCollection} loading={loading} />
                </>
            );
        }

        if (selectedTab === "interviewGuides") return <InterviewGuides />;
        if (selectedTab === "careerGuides") return <CareerGuides />;
        return "";
    };

    return (
        <>
            <Header type="LoggedIn" />
            <div className="learn-with-hubert-wrapper">
                <BannerSection />
                <div className="custom-tab-selector-wrapper">
                    <TabSelector
                        variant="pill"
                        tabs={tabs}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        hugContent
                    />
                </div>
                {renderComponents()}
            </div>
            <Footer />
        </>
    );
};

export default LearnWithHubert;
