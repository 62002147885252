import { useMutation } from "@apollo/client";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "react-toastify";
import { VerifyEmailResponse } from "../behavior/graphTypes/authenticationTypes";
import { VERIFY_EMAIL } from "../behavior/mutations/auth.mutation";
import { PageRoute, PageState } from "../constants";
import TransitionPage from "../pages/transition/TransitionPage";

const EmailVerification = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const shouldExecute = useRef(true);
    const navigate = useNavigate();
    const [verifyEmail] = useMutation<VerifyEmailResponse>(VERIFY_EMAIL);

    const verifyCode = async (code: string, email: string): Promise<void> => {
        try {
            const data = await verifyEmail({
                variables: { code, email },
            });

            const response = data.data?.verifyEmail;
            if (!response) {
                toast.error<string>(t("AppMessages.server-error"));
                return;
            }
            const encodedEmail = encodeURIComponent(email);
            if (response.isError && response.errorMessage) {
                toast.error<string>(t(`AppMessages.${response.errorMessage}`));
                if (response.errorMessage === PageState.EMAIL_PREVIOUSLY_VERIFIED) {
                    navigate(`/?state=${PageState.EMAIL_PREVIOUSLY_VERIFIED}&email=${encodedEmail}`, { replace: true });
                    return;
                }
                navigate(PageRoute.ROOT, { replace: true });
            } else {
                navigate(`/?state=${PageState.VERIFIED_EMAIL}&email=${encodedEmail}`, {
                    replace: true,
                });
            }
        } catch (e) {
            toast.error<string>(t((e as Error).message));
        }
    };

    useEffect(() => {
        if (!shouldExecute.current) {
            return;
        }
        shouldExecute.current = false;

        const mode = searchParams.get("mode");
        const code = searchParams.get("oobCode");
        const email = searchParams.get("email");

        if (mode === "verifyEmail" && code && email) {
            verifyCode(code, email);
        } else {
            navigate(PageRoute.ROOT, { replace: true });
        }
    }, []);

    return (
        <TransitionPage
            title={t("AppMessages.verifying-email-title")}
            subtitle={t("AppMessages.verifying-email-subtitle")}
        />
    );
};

export default EmailVerification;
