import { useLazyQuery, useQuery } from "@apollo/client";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Illustration from "../../assets/images/illustration-2.png";
import { GET_SHALLOW_CONTENTS } from "../../behavior/queries/content.query";
import { GET_VIEWER_INFORMATION } from "../../behavior/queries/viewer.query";
import { PageRoute } from "../../constants";
import { ContentCollection } from "../../types/content";
import { Viewer } from "../../types/viewer";
import { ArticleCardList } from "../common";
import { Button, Heading, HorizontalLine, Image } from "../elements";

const LearnWithHubert = () => {
    const { t } = useTranslation();
    const { data } = useQuery<{ viewer: Viewer }>(GET_VIEWER_INFORMATION, {
        fetchPolicy: "cache-only",
    });

    const [contentData, setContentData] = useState<ContentCollection>();
    const [getContentData, { loading }] = useLazyQuery<{
        contentCollection: ContentCollection;
    }>(GET_SHALLOW_CONTENTS, {
        variables: {
            candidateFilterInput: {
                tags: [],
                optionalTags: [],
                pagination: {
                    limit: 4,
                    page: 1,
                },
            },
        },
    });

    const fetchContent = async () => {
        const contents = await getContentData();
        if (contents.data?.contentCollection) {
            setContentData(contents.data?.contentCollection);
        }
    };

    useEffect(() => {
        if (data?.viewer.id) {
            fetchContent();
        }
    }, [data]);

    return (
        <div className="learn-with-hubert-wrapper">
            <Heading level="h1">{t("Dashboard.LearnWithHubert.Title")}</Heading>
            <Heading level="h4" light>
                {t("Dashboard.LearnWithHubert.Subtitle")}
            </Heading>
            <Image src={Illustration} alt="Illustration" className="illustration" />
            <div className="content-wrapper">
                <div className="title-section">
                    <Heading level="h5">{t("Dashboard.LearnWithHubert.PickedForMe")}</Heading>
                    <Button size="small" secondary link to={PageRoute.LEARN_WITH_HUBERT}>
                        <div>{t("Dashboard.LearnWithHubert.ButtonText_SeeAllArticles")}</div>
                        <ArrowRight size={20} className="ml-1.5" />
                    </Button>
                </div>
                <HorizontalLine weight="semi-dark" />
                <ArticleCardList articles={contentData?.contents ?? null} loading={loading || !contentData} />
            </div>
        </div>
    );
};

export default LearnWithHubert;
