import { AlertCircle, AlertTriangle, CheckCircle, Info, Loader } from "lucide-react";
import { ReactNode } from "react";
import Button from "./Button";
import Heading from "./Heading";

export interface LargeAlertProps {
    title: string;
    subtitle: string;
    type: "loading" | "success" | "info" | "warning" | "danger";
    children?: ReactNode;
    button?: {
        to: string;
        label: string;
    };
}

const LargeAlert = ({ title, subtitle, type, children, button }: LargeAlertProps) => {
    const getIcon = () => {
        switch (type) {
            case "loading":
                return <Loader size={64} />;
            case "success":
                return <CheckCircle size={64} />;
            case "info":
                return <Info size={64} />;
            case "warning":
                return <AlertTriangle size={64} />;
            case "danger":
                return <AlertCircle size={64} />;
            default:
                return "";
        }
    };

    return (
        <div className={`large-alert-wrapper ${type}`}>
            {getIcon()}
            <Heading level="h3">{title}</Heading>
            <Heading level="h4" light>
                {subtitle}
            </Heading>
            {children}
            {button && (
                <Button className="mt-3" to={button.to} submit>
                    {button.label}
                </Button>
            )}
        </div>
    );
};

export default LargeAlert;
