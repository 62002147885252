import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface SkeletonLoaderProps {
    count?: number;
    containerClassName?: string;
    circle?: boolean;
    width?: string | number;
    height?: string | number;
    className?: string;
}

const SkeletonLoader = ({
    count = 1,
    containerClassName = "skeleton-loading",
    circle = false,
    width = "100%",
    height = "100%",
    className,
}: SkeletonLoaderProps) => (
    <SkeletonTheme>
        <Skeleton
            count={count}
            className={className}
            containerClassName={containerClassName}
            circle={circle}
            width={width}
            height={height}
        />
    </SkeletonTheme>
);

export default SkeletonLoader;
