import { JobPosting } from "../../../types/jobPosting";
import { HorizontalLine, Text } from "../../elements";

type JobPostingCardProps = {
    jobPosting: JobPosting;
};

const JobPostingCard = ({ jobPosting }: JobPostingCardProps) => {
    const { title, company, locations, url } = jobPosting;

    const renderLocation = () => {
        if (locations[0]) {
            return (
                <div>
                    <Text weight="light">{`${locations[0].city}, ${locations[0].country}`}</Text>
                </div>
            );
        }

        return null;
    };

    return (
        <div key={url} className="job-posting-card">
            <a className="body" href={url} target="_blank" rel="noreferrer">
                <div>
                    <Text size="large" weight="semibold">
                        {company}
                    </Text>
                </div>
                <div>
                    <Text weight="regular">{title}</Text>
                </div>
                <HorizontalLine className="mt-5 mb-2" weight="medium" />
                {renderLocation()}
            </a>
        </div>
    );
};

export default JobPostingCard;
