import { Link } from "react-router";
import { Content } from "../../types/content";
import { Image, Text } from "../elements";

interface ArticleCardProps {
    article: Content;
    to: string;
}

const ArticleCard = ({ article, to }: ArticleCardProps) => (
    <Link className="article-card custom-article-card" to={to}>
        <Image
            className="article-image"
            src={article.featureImage ?? ""}
            alt={article.featureImageAlt ?? ""}
            skeletonUntilFullLoad
        />
        <div className="article-tag">
            <span>{article.primaryTag}</span>
        </div>
        <Text block weight="medium" size="large">
            {article.title}
        </Text>
    </Link>
);

export default ArticleCard;
