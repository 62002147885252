import { useState } from "react";
import SkeletonLoader from "./SkeletonLoader";

interface ImageProps {
    className?: string;
    src?: string;
    alt?: string;
    loading?: boolean;
    circle?: boolean;
    skeletonUntilFullLoad?: boolean;
}

const Image = ({
    src = "",
    alt = "",
    className = "",
    loading = false,
    circle = false,
    skeletonUntilFullLoad = false,
}: ImageProps) => {
    const [loaded, setLoaded] = useState(false);
    const skeletonClass = loading ? "skeleton-loading" : "";

    if (skeletonUntilFullLoad) {
        return (
            <>
                {!loaded && (
                    <div className={`skeleton-loading ${className}`}>
                        <SkeletonLoader count={1} circle={circle} />
                    </div>
                )}
                <img
                    src={src}
                    alt={alt}
                    className={`${className} ${!loaded ? "hidden" : ""}`}
                    onLoad={() => setLoaded(true)}
                />
            </>
        );
    }

    return loading ? (
        <div className={`${className} ${skeletonClass}`}>
            <SkeletonLoader count={1} circle={circle} />
        </div>
    ) : (
        <img src={src} alt={alt} className={`${className}`} />
    );
};

export default Image;
