import classNames from "classnames";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Text from "./Text";

interface TabSelectorProps {
    variant?: "pill" | "link";
    tabs: TabType[];
    selectedTab: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
    hugContent?: boolean;
}

type TabType = {
    textKey: string;
    value: string;
};

const TabSelector = ({ variant = "link", tabs, selectedTab, setSelectedTab, hugContent = false }: TabSelectorProps) => {
    const { t } = useTranslation();

    return (
        <div className={classNames("tab-selector-wrapper", hugContent && "hug-content", `${variant}-variant`)}>
            {tabs.map((tab) => (
                <div className={classNames("tab-wrapper", selectedTab === tab.value && "active")} key={tab.value}>
                    <Text weight="semibold" className="tab" onClick={() => setSelectedTab(tab.value)}>
                        {t(tab.textKey)}
                    </Text>
                    <span className="line-bottom" />
                </div>
            ))}
        </div>
    );
};

export default TabSelector;
