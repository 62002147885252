import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { ApplicationStatus, PageRoute } from "../../../constants";
import Application from "../../../types/application";
import { generateLiteralDay } from "../../../utils/dateUtilities";
import { Button, ToolTip } from "../../elements";
import StartInterviewButton from "../startInterviewButton/StartInterviewButton";

interface InterviewsTableProps {
    yourInterviews: Application[];
    loading: boolean;
}

const InterviewsTable = ({ yourInterviews, loading }: InterviewsTableProps) => {
    const { t } = useTranslation();

    const renderNotScoredInterviews = (application: Application) => {
        if (application.interview) {
            const { interview } = application;

            if (interview.status === ApplicationStatus.NOT_SCORED) {
                return (
                    <ToolTip message={t("Common.MyInterviews.TableBody_NotScored")} align="items-end">
                        <Button size="small" secondary disabled>
                            {t("Common.MyInterviews.ButtonText_NotScored")}
                        </Button>
                    </ToolTip>
                );
            }

            return (
                <ToolTip message={t("Common.MyInterviews.TableBody_StartInterview")} align="items-end">
                    <StartInterviewButton
                        btnLabel={t("Common.MyInterviews.ButtonText_StartInterview")}
                        hidden={loading}
                        applicationId={application.id}
                        interviewId={interview.id}
                        oldInterviewFlow={application.job?.candidateApp !== true}
                    />
                </ToolTip>
            );
        }
        return null;
    };

    const getPrefix = (application: Application) => {
        const { interview } = application;
        return interview?.status !== ApplicationStatus.TO_DO ? null : t("Common.MyInterviews.ApplicationDate_Invited");
    };

    return (
        <table className="interviews-table">
            <thead>
                <tr>
                    <th className="w-1/6">{t("Common.MyInterviews.TableHead_Employer")}</th>
                    <th className="w-3/6">{t("Common.MyInterviews.TableHead_Job")}</th>
                    <th className="w-1/6">{t("Common.MyInterviews.TableHead_ApplicationDate")}</th>
                    <th className="w-1/6" aria-label="Table head for button column" />
                </tr>
            </thead>
            <tbody>
                {yourInterviews &&
                    yourInterviews.map((row) => (
                        <tr key={`${row.id}`}>
                            <td>{row.job?.company}</td>
                            <td>{row.job?.title}</td>
                            <td>{generateLiteralDay(row.createdAt, getPrefix(row), t)}</td>
                            <td>
                                {row.interview?.status !== ApplicationStatus.SCORED ? (
                                    renderNotScoredInterviews(row)
                                ) : (
                                    <ToolTip message={t("Common.MyInterviews.TableBody_Details")} align="items-end">
                                        <Button size="small" secondary to={`${PageRoute.INTERVIEWS}/${row.id}`}>
                                            {t("Common.MyInterviews.ButtonText_Details")}
                                            <ArrowRight size={20} className="ml-1.5" />
                                        </Button>
                                    </ToolTip>
                                )}
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    );
};

export default InterviewsTable;
