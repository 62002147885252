import * as Yup from "yup";

export const registrationFormSchema = Yup.object().shape({
    firstName: Yup.string().required("Registration.Error_FirstName_Required"),
    lastName: Yup.string().required("Registration.Error_LastName_Required"),
    email: Yup.string().required("Registration.Error_Email_Required").email("Registration.Error_Email_Invalid"),
    password: Yup.string()
        .min(6, "Registration.Error_Password_6_Characters")
        .required("Registration.Error_Password_Required"),
    phoneNumber: Yup.string().matches(/^(?:[0-9] ?){3,14}[0-9]$/g, "Registration.Error_PhoneNumber_Invalid"),
});

export const registrationInviteEmailFormSchema = Yup.object().shape({
    password: Yup.string()
        .min(6, "Registration.Error_Password_6_Characters")
        .required("Registration.Error_Password_Required"),
});
