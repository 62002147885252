import { useTranslation } from "react-i18next";
import { Heading, Text } from "../../../elements";

const timelineEvents = [
    {
        title: "Interview.HowHubertScoresInterviews.EventOne_Title",
        description: "Interview.HowHubertScoresInterviews.EventOne_Description",
    },
    {
        title: "Interview.HowHubertScoresInterviews.EventTwo_Title",
        description: "Interview.HowHubertScoresInterviews.EventTwo_Description",
    },
    {
        title: "Interview.HowHubertScoresInterviews.EventThree_Title",
        description: "Interview.HowHubertScoresInterviews.EventThree_Description",
    },
];

const RecruitmentProcess = () => {
    const { t } = useTranslation();

    return (
        <div className="recruitment-process-wrapper">
            <Heading level="h4" className="mb-2">
                {t("Interview.HowHubertScoresInterviews.RecruitmentProcess_Title")}
            </Heading>
            <Text>{t("Interview.HowHubertScoresInterviews.RecruitmentProcess_Description")}</Text>
            <div className="timeline-wrapper">
                {timelineEvents.map((event, index) => (
                    <div className="timeline-event" key={event.title}>
                        <div className={`timeline-line ${timelineEvents.length === index + 1 ? "hidden" : ""}`} />
                        <div>
                            <div className="knob" />
                        </div>
                        <div>
                            <Text weight="semibold" size="large" block className="mb-2">
                                {t(event.title)}
                            </Text>
                            <Text block>{t(event.description)}</Text>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default RecruitmentProcess;
