export enum ProviderId {
    GOOGLE = "google.com",
    PASSWORD = "password",
}

export enum Providers {
    GOOGLE = "Google",
}

export enum TokenAction {
    SIGNUP = "sign-up",
}

export enum ApplicationError {
    EMAIL_NOT_VERIFIED = "email-not-verified",
}

export enum AssessedMatch {
    GREAT_MATCH = "greatMatch",
    OK_MATCH = "okMatch",
    GOT_POTENTIAL = "gotPotential",
    NOT_CLEAR_MATCH = "notClearMatch",
}

export enum ProfessionalExperience {
    OK_MATCH = "okMatch",
    NOT_CLEAR_MATCH = "notClearMatch",
}

export enum UnProtectedOperations {
    SIGNIN_EMAIL_PASSWORD = "SIGNIN_EMAIL_PASSWORD",
    SIGNUP_EMAIL_PASSWORD = "SIGNUP_EMAIL_PASSWORD",
    SIGNUP_EMAIL_TOKEN = "SIGNUP_EMAIL_TOKEN",
    SIGNUP_EMAIL_RTS_TOKEN = "SIGNUP_EMAIL_RTS_TOKEN",
    SIGNIN_FEDERATED_LOGIN = "SIGNIN_FEDERATED_LOGIN",
    VERIFY_EMAIL = "VERIFY_EMAIL",
    REFRESH_TOKEN = "REFRESH_TOKEN",
    INVITATION = "INVITATION",
    EXISTING_EMAIL = "EXISTING_EMAIL",
    RESET_PASSWORD = "RESET_PASSWORD",
    SET_PASSWORD_BY_CODE = "SET_PASSWORD_BY_CODE",
    SEND_BENEFITS_EMAIL = "SEND_BENEFITS_EMAIL",
    UNSUBSCRIBE_REMINDER = "UNSUBSCRIBE_REMINDER",
    OPENED_DASHBOARD = "OPENED_DASHBOARD",
    EXCHANGE_IMPERSONATE_TOKEN = "EXCHANGE_IMPERSONATE_TOKEN",
}

export enum ApplicationStatus {
    TO_DO = "TO_DO",
    NOT_SCORED = "NOT_SCORED",
    SCORED = "SCORED",
}

export enum ApplicationLanguage {
    EN_EU = "en-EU",
    SV_SE = "sv-SE",
    DE_DE = "de-DE",
    NL_NL = "nl-NL",
    PT_PT = "pt-PT",
    TR_TR = "tr-TR",
    NO_NO = "no-NO",
    FI_FI = "fi-FI",
}

export const PageState = {
    SIGN_IN: "sign-in",
    SIGN_UP: "sign-up",
    INVITATION_SIGN_IN: "sign-in-invitation",
    INVITATION_SIGN_UP: "sign-up-invitation",
    INTERVIEW_COMPLETED: "interview-completed",
    INVITATION_POST_INTERVIEW_SIGN_IN: "invitation-post-interview-sign-in",
    INVITATION_POST_INTERVIEW_SIGN_UP: "invitation-post-interview-sign-up",
    JOB_APPLY_PRE_INTERVIEW_SIGN_IN: "job-apply-pre-interview-sign-in",
    JOB_APPLY_POST_INTERVIEW_SIGN_IN: "job-apply-post-interview-sign-in",
    JOB_APPLY_POST_INTERVIEW_SIGN_UP: "job-apply-post-interview-sign-up",
    VERIFIED_EMAIL: "verified-email",
    EMAIL_PREVIOUSLY_VERIFIED: "email-already-verified",
    PASSWORD_RESET: "passwordReset",
    LINK_PASSWORD: "link-password",
    SESSION_EXPIRED: "session-expired",
    LOGOUT: "logout",
} as const;

export type TPageState = (typeof PageState)[keyof typeof PageState];

export const PageRoute = {
    ROOT: "/",
    REGISTRATION: "/registration",
    PRE_INTERVIEW_REGISTRATION: "/registration-invite-email",
    POST_INTERVIEW_REGISTRATION: "/interview-completed-invite-email",
    PRE_INTERVIEW_LOGIN: "/login-invite-email",
    POST_INTERVIEW_LOGIN: "/login-interview-completed-invite-email",
    PRE_INTERVIEW: "/pre-interview",
    GOOGLE_CALLBACK: "/google-callback",
    EMAIL_VERIFICATION_CALLBACK: "/verify-email",
    INVITATION_VERIFICATION: "/invitation",
    PASSWORD_RESET: "/forgot-password",
    INTERVIEW_ADVICE: "/advice-before-hubert-interview",
    TRANSITION_PAGE: "/loading",
    DASHBOARD: "/dashboard",
    INTERVIEWS: "/my-interviews",
    PROFILE: "/my-profile",
    LEARN_WITH_HUBERT: "/learn-with-hubert",
    LEARN_WITH_HUBERT_ARTICLE: "/learn-with-hubert/article",
    HELP_CENTER: "/help-center",
    UNSUBSCRIBE_EMAIL: "/unsubscribe-from-emails",
    INSTANT_APPLY: {
        INDEX: "/instant-apply",
        LOGIN: "login",
        PRE_INTERVIEW: "pre-interview",
        POST_INTERVIEW: "post-interview",
        POST_INTERVIEW_SIGNIN: "post-interview-signIn",
        POST_INTERVIEW_SIGNUP: "post-interview-signUp",
    },
    IMPERSONATION: "/impersonate",
} as const;
