import { useLazyQuery, useMutation } from "@apollo/client";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "react-toastify";
import { SEND_BENEFITS_EMAIL } from "../../../behavior/mutations/invitation.mutation";
import { GET_INVITATION } from "../../../behavior/queries/invitation.query";
import Header from "../../../components/header/Header";
import CompletedAlert from "../../../components/interview/CompletedAlert";
import { PageState, TPageState } from "../../../constants";
import { Invitation, InvitationTokenPayload } from "../../../types/invitation";
import InviteEmailSignIn from "./InviteEmailSignIn";
import "./LoginInviteEmail.scss";

const LoginInviteEmailInterviewCompleted = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [getInvitation] = useLazyQuery(GET_INVITATION);
    const [sendBenefitsEmail] = useMutation(SEND_BENEFITS_EMAIL);
    const [invitation, setInvitation] = useState<Invitation>();
    const [pageState, setPageState] = useState<TPageState>(PageState.INVITATION_POST_INTERVIEW_SIGN_IN);
    const [searchParams] = useSearchParams();
    const [email, setEmail] = useState<string>();

    const verify = useCallback(
        async (token: string): Promise<void> => {
            try {
                const response = await getInvitation({
                    variables: {
                        token,
                    },
                    fetchPolicy: "no-cache",
                });

                if (!response) {
                    toast.error<string>(t("AppMessages.server-error"));
                    return;
                }

                const type = response.data.invitation.__typename;
                if (type !== "Invitation") {
                    toast.error<string>(t(`ProcessInvitation.${type}`));
                    navigate("/", { replace: true });
                    return;
                }

                const invitationData = response.data.invitation as Invitation;
                if (invitationData.interviewCompleted) {
                    await sendBenefitsEmail({
                        variables: {
                            token,
                        },
                    });
                    setInvitation(invitationData);
                } else {
                    toast.error<string>(t("InterviewCompletedPageInviteEmail.InterviewNotCompleted"));
                    navigate("/", { replace: true });
                }
            } catch (e) {
                toast.error<string>(t((e as Error).message));
            }
        },
        [navigate, sendBenefitsEmail, t, getInvitation],
    );

    useEffect(() => {
        const stateParam = searchParams.get("state");
        if (stateParam) {
            const state = stateParam as TPageState;
            setPageState(state);
        }

        const token = searchParams.get("token");
        if (token) {
            const jwt = jwtDecode<InvitationTokenPayload>(token);
            setEmail(jwt?.email);
            verify(token);
        }
    }, [navigate, searchParams, verify]);

    return (
        <>
            <Header />
            <div className="login-interview-completed-invite-email-wrapper">
                <CompletedAlert />
                <InviteEmailSignIn invitation={invitation} state={pageState} email={email} />
            </div>
        </>
    );
};

export default LoginInviteEmailInterviewCompleted;
