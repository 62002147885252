import HubertLogo from "../../assets/images/hubert-logo.png";
import { Image } from "../elements";
import "./Footer.scss";

const Footer = () => (
    <div className="footer-wrapper">
        <Image className="hubert-logo" src={HubertLogo} alt="hubert-logo" />
    </div>
);

export default Footer;
