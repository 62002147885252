import { gql } from "@apollo/client";

export const GET_SHALLOW_CONTENTS = gql`
    query ContentCollection($candidateFilterInput: ContentFilterInput) {
        contentCollection(candidateFilterInput: $candidateFilterInput) {
            contents {
                id
                title
                featureImage
                featureImageAlt
                primaryTag
                excerpt
            }
            meta {
                pagination {
                    page
                    limit
                    pages
                    total
                }
            }
        }
    }
`;

export const GET_CONTENT = gql`
    query Content($contentId: String) {
        content(contentId: $contentId) {
            ... on Content {
                id
                title
                slug
                excerpt
                html
                featureImage
                featureImageAlt
                publishedOn
                primaryTag
                primaryAuthor
            }
            ... on RecordNotFound {
                message
            }
        }
    }
`;

export const GET_ARTICLE_CARD_INFO = gql`
    query ContentCollection($candidateFilterInput: ContentFilterInput) {
        contentCollection(candidateFilterInput: $candidateFilterInput) {
            contents {
                id
                title
                primaryTag
            }
            meta {
                pagination {
                    page
                    limit
                }
            }
        }
    }
`;
