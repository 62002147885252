import { useLazyQuery } from "@apollo/client";
import { Menu } from "lucide-react";
import { memo, MouseEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import useBreakpoint from "use-breakpoint";
import HubertLogo from "../../assets/images/hubert-logo.png";
import client from "../../behavior/apolloClient";
import { GET_VIEWER_INFORMATION } from "../../behavior/queries/viewer.query";
import { PageRoute } from "../../constants";
import { Viewer } from "../../types/viewer";
import { BREAKPOINTS } from "../../utils/constants/breakpoints";
import { setCookie } from "../../utils/cookieHandlers";
import { Button, Image } from "../elements";
import "./Header.scss";
import LanguageSelector from "./LanguageSelector";
import MobileNavExpanded from "./MobileNavExpanded";
import Nav from "./Nav";
import UserDropdown from "./UserDropdown";

interface HeaderProps {
    type?:
        | "LoggedIn"
        | "Login"
        | "Registration"
        | "RegistrationInviteEmail"
        | "SignInInviteEmail"
        | "PublicArticle"
        | "";
    interviewRedirect?: () => void;
}

interface RightSideSectionProps {
    buttonText: string;
    onClick?: MouseEventHandler;
    to?: string;
    noLanguageSelector?: boolean;
}

const RightSideSection = ({
    buttonText,
    onClick = () => "null",
    to = "",
    noLanguageSelector = false,
}: RightSideSectionProps) => (
    <div className="right-side-section-wrapper">
        {!noLanguageSelector ? <LanguageSelector /> : ""}
        <Button size="small" secondary to={to} onClick={onClick}>
            {buttonText}
        </Button>
    </div>
);

const Header = ({ type = "", interviewRedirect = () => null }: HeaderProps) => {
    const { t } = useTranslation();
    const [navbarExpanded, setNavbarExpanded] = useState(false);
    const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");
    const isMobile = breakpoint === "sm" || breakpoint === "md";
    const [getViewerInformation, { data }] = useLazyQuery<{ viewer: Viewer }>(GET_VIEWER_INFORMATION);

    useEffect(() => {
        if (isMobile && navbarExpanded) {
            document.body.classList.add("overflow-hidden");
        } else document.body.classList.remove("overflow-hidden");

        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [isMobile, navbarExpanded]);

    useEffect(() => {
        if (type === "LoggedIn") {
            getViewerInformation();
        }
    }, [type]);

    useEffect(() => {
        const setPreferredLanguageCookie = async () => {
            const viewerLanguage = data?.viewer.language;
            if (viewerLanguage) {
                setCookie("preferred-language", viewerLanguage);
                await client.refetchQueries({
                    include: "active",
                });
            }
        };

        if (type === "LoggedIn") {
            setPreferredLanguageCookie();
        }
    }, [data]);

    const renderComponents = () => {
        switch (type) {
            case "LoggedIn":
                return !isMobile ? (
                    <>
                        <Nav isMobile={isMobile} />
                        <UserDropdown isMobile={isMobile} viewer={data?.viewer} />
                    </>
                ) : (
                    <Menu className="menu-icon" onClick={() => setNavbarExpanded((prevState) => !prevState)} />
                );
            case "Registration":
                return <RightSideSection buttonText={t("Header.ButtonText_SignInToYourAccount")} to="/" />;
            case "Login":
                return (
                    <RightSideSection
                        buttonText={t("Header.ButtonText_CreateNewAccount")}
                        to={PageRoute.REGISTRATION}
                    />
                );
            case "RegistrationInviteEmail":
                return (
                    <RightSideSection
                        buttonText={t("Header.ButtonText_StartInterviewWithoutSigningUp")}
                        onClick={interviewRedirect}
                    />
                );
            case "SignInInviteEmail":
                return (
                    <RightSideSection
                        buttonText={t("Header.ButtonText_StartInterviewWithoutSigningIn")}
                        onClick={interviewRedirect}
                    />
                );
            case "PublicArticle":
                return (
                    <RightSideSection
                        buttonText={t("Header.ButtonText_SignInToYourAccount")}
                        to="/"
                        noLanguageSelector
                    />
                );
            default:
                return <LanguageSelector />;
        }
    };

    return (
        <div className="header-wrapper">
            {type === "LoggedIn" ? (
                <Link to={PageRoute.DASHBOARD}>
                    <Image className="hubert-logo" src={HubertLogo} alt="hubert-logo" />
                </Link>
            ) : (
                <a href="/">
                    <Image className="hubert-logo" src={HubertLogo} alt="hubert-logo" />
                </a>
            )}
            {renderComponents()}
            {type === "LoggedIn" && isMobile && navbarExpanded && (
                <MobileNavExpanded isMobile={isMobile} setNavbarExpanded={setNavbarExpanded} />
            )}
        </div>
    );
};

export default memo(Header);
