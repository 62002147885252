import { AlertCircle } from "lucide-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./ProtectedRoute";
import { ApplicationLanguage, PageRoute } from "./constants";
import VerifyEmail from "./helpers/EmailVerification";
import GoogleCallback from "./helpers/google/GoogleCallback";
import CenteredLayout from "./layouts/CenteredLayout";
import ErrorPage from "./pages/ErrorPage";
import Impersonation from "./pages/impersonation/Impersonation";
import {
    AdviceBeforeInterviewArticle,
    Article,
    Dashboard,
    ForgotPassword,
    HelpCenter,
    Interview,
    InterviewCompletedPageInviteEmail,
    LearnWithHubert,
    Login,
    LoginInviteEmail,
    LoginInviteEmailInterviewCompleted,
    PreInterviewPage,
    Registration,
    UnsubscribeFromEmails,
    YourInterviews,
    YourProfile,
} from "./pages/index";
import PostInstantApply from "./pages/instantJobApply/PostInstantApply";
import PreInstantApply from "./pages/instantJobApply/PreInstantApply";
import ProcessInvitation from "./pages/invitation/ProcessInvitation";
import RealTimeScreeningLogin from "./pages/login/RealTimeScreening/Login";
import RealTimeScreeningPostInterviewLogin from "./pages/login/RealTimeScreening/PostInterviewLogin";
import RealTimeScreeningPostInterviewRegister from "./pages/login/RealTimeScreening/PostInterviewRegister";
import TransitionPage from "./pages/transition/TransitionPage";
import ScrollToTop from "./utils/ScrollToTop";
import { getCookie } from "./utils/cookieHandlers";
import { useHydrateUserSession } from "./utils/customHooks";

const App = () => {
    const { i18n } = useTranslation();
    useHydrateUserSession();

    // Set app language from cookie, otherwise from browser language
    useEffect(() => {
        const languageCookie = getCookie("language");
        const preferredLanguageCookie = getCookie("preferred-language");

        if (languageCookie) {
            i18n.changeLanguage(languageCookie);
        } else if (preferredLanguageCookie) {
            i18n.changeLanguage(preferredLanguageCookie);
        } else if (navigator.languages) {
            const supportedLocaleCodes = Object.values(ApplicationLanguage);
            const supportedLanguageCodes = supportedLocaleCodes.map((lang) => lang.slice(0, 2));

            const browserLanguages = navigator.languages.map((lang) => lang.slice(0, 2));
            const language = browserLanguages.find((lang) => supportedLanguageCodes.includes(lang));

            if (language) i18n.changeLanguage(supportedLocaleCodes.find((locale) => locale.startsWith(language)));
            else i18n.changeLanguage(ApplicationLanguage.EN_EU);
        }
    }, [i18n]);

    return (
        <>
            <ToastContainer />
            <ScrollToTop>
                <Routes>
                    <Route element={<CenteredLayout />}>
                        <Route path={PageRoute.ROOT} element={<Login />} />
                        <Route path={PageRoute.REGISTRATION} element={<Registration />} />
                        <Route
                            path={PageRoute.POST_INTERVIEW_REGISTRATION}
                            element={<InterviewCompletedPageInviteEmail />}
                        />
                        <Route path={PageRoute.PRE_INTERVIEW_LOGIN} element={<LoginInviteEmail />} />
                        <Route path={PageRoute.POST_INTERVIEW_LOGIN} element={<LoginInviteEmailInterviewCompleted />} />
                        <Route path={PageRoute.PRE_INTERVIEW} element={<PreInterviewPage />} />
                        <Route path={PageRoute.GOOGLE_CALLBACK} element={<GoogleCallback />} />
                        <Route path={PageRoute.EMAIL_VERIFICATION_CALLBACK} element={<VerifyEmail />} />
                        <Route path={PageRoute.INVITATION_VERIFICATION} element={<ProcessInvitation />} />
                        <Route path={PageRoute.INSTANT_APPLY.INDEX}>
                            <Route path={PageRoute.INSTANT_APPLY.POST_INTERVIEW} element={<PostInstantApply />} />
                            <Route path={PageRoute.INSTANT_APPLY.PRE_INTERVIEW} element={<PreInstantApply />} />
                            <Route path={PageRoute.INSTANT_APPLY.LOGIN} element={<RealTimeScreeningLogin />} />
                            <Route
                                path={PageRoute.INSTANT_APPLY.POST_INTERVIEW_SIGNIN}
                                element={<RealTimeScreeningPostInterviewLogin />}
                            />
                            <Route
                                path={PageRoute.INSTANT_APPLY.POST_INTERVIEW_SIGNUP}
                                element={<RealTimeScreeningPostInterviewRegister />}
                            />
                        </Route>
                        <Route path={PageRoute.PASSWORD_RESET} element={<ForgotPassword />} />
                        <Route
                            path={`${PageRoute.INTERVIEW_ADVICE}/:lang`}
                            element={<AdviceBeforeInterviewArticle />}
                        />
                        <Route path={PageRoute.TRANSITION_PAGE} element={<TransitionPage />} />
                        <Route path="" />
                        <Route element={<ProtectedRoute />}>
                            <Route path={PageRoute.DASHBOARD} element={<Dashboard />} />
                            <Route path={PageRoute.INTERVIEWS}>
                                <Route index element={<YourInterviews />} />
                                <Route path=":id" element={<Interview />} />
                            </Route>
                            <Route path={PageRoute.PROFILE} element={<YourProfile />} />
                            <Route path={PageRoute.LEARN_WITH_HUBERT} element={<LearnWithHubert />} />
                            <Route path={PageRoute.HELP_CENTER} element={<HelpCenter />} />
                            <Route path={`${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/:id`} element={<Article />} />R
                        </Route>
                        <Route path={PageRoute.UNSUBSCRIBE_EMAIL} element={<UnsubscribeFromEmails />} />
                        <Route path={PageRoute.IMPERSONATION} element={<Impersonation />} />
                        <Route
                            path="*"
                            element={
                                <ErrorPage
                                    Icon={AlertCircle}
                                    title="Are you sure you were looking for this ?"
                                    description="The page you are looking for does not exist."
                                    hideNextSteps={false}
                                />
                            }
                        />
                    </Route>
                </Routes>
            </ScrollToTop>
        </>
    );
};

export default App;
