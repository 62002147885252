import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import MiniLogoImage from "../../../assets/images/hubert-logo-mini.png";
import { Heading, LargeAlert, OrHorizontalLine } from "../../../components/elements";
import { LargeAlertProps } from "../../../components/elements/LargeAlert";
import Header from "../../../components/header/Header";
import CompletedAlert from "../../../components/interview/CompletedAlert";
import { BenefitsBox, RegistrationInviteEmailForm, SocialMediaRegistration } from "../../../components/registration";
import { TPageState } from "../../../constants";
import useDataSerializer from "../../../hooks/useDataSerializer";

const RealTimeScreeningPostInterviewRegister = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { deserialize } = useDataSerializer();

    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: "",
        subtitle: "",
        type: "info",
    });

    const pageState = useMemo(() => searchParams.get("state") as TPageState, [searchParams]);

    const job = useMemo(() => {
        const jobParam = searchParams.get("job");
        if (jobParam) {
            return deserialize<{ id: string; title: string }>(jobParam);
        }
    }, [deserialize, searchParams]);

    return (
        <>
            <Header />
            <div className="login-interview-completed-invite-email-wrapper">
                <CompletedAlert />
                <div className="registration-invite-email-wrapper">
                    <div className="left-section">
                        <Heading level="h2" light>
                            {job?.title}
                        </Heading>
                        <Heading level="h4">{t("RegistrationInviteEmail.Subtitle_AfterInterviewCompleted")}</Heading>
                        <BenefitsBox />
                    </div>
                    <div className="right-section">
                        <img className="hubert-logo-mini" src={MiniLogoImage} alt="Hubert logo mini" />
                        <Heading level="h4">{t("Registration.RegistrationTitle")}</Heading>
                        {showAlert.title ? (
                            <LargeAlert title={showAlert.title} subtitle={showAlert.subtitle} type={showAlert.type} />
                        ) : (
                            <div>
                                <RegistrationInviteEmailForm setShowAlert={setShowAlert} state={pageState} />
                                <OrHorizontalLine />
                                <SocialMediaRegistration />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RealTimeScreeningPostInterviewRegister;
