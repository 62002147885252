import { Check } from "lucide-react";
import { useTranslation } from "react-i18next";

type BenefitPointType = {
    textLabel: string;
};

const BenefitPoint = ({ textLabel }: BenefitPointType) => (
    <div className="benefit-point">
        <Check />
        <span>{textLabel}</span>
    </div>
);

const BenefitsBox = () => {
    const { t } = useTranslation();

    return (
        <div className="benefits-box-wrapper">
            <div className="benefit-box-title">{`${t("Registration.BenefitsBox_Title")}:`}</div>
            <BenefitPoint textLabel={t("Registration.BenefitsBox_Point1")} />
            <BenefitPoint textLabel={t("Registration.BenefitsBox_Point2")} />
            <BenefitPoint textLabel={t("Registration.BenefitsBox_Point3")} />
            <BenefitPoint textLabel={t("Registration.BenefitsBox_Point4")} />
            <BenefitPoint textLabel={t("Registration.BenefitsBox_Point5")} />
            <BenefitPoint textLabel={t("Registration.BenefitsBox_Point6")} />
        </div>
    );
};

export default BenefitsBox;
