import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router";
import { GET_VIEWER_INTERVIEW_SUMMARY } from "../../behavior/queries/application.query";
import { ArticleLinkCard, HubertTip, YourInterviewsTable } from "../../components/common";
import { LearnWithHubert, YourCareer } from "../../components/dashboard";
import InterviewCompletedModal from "../../components/dashboard/InterviewCompletedModal";
import LatestJobPostings from "../../components/dashboard/latestJobPostings";
import NewCandidateModalPopup from "../../components/dashboard/NewCandidateModalPopup";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { PageState } from "../../constants";
import Application from "../../types/application";
import { Viewer } from "../../types/viewer";
import "./Dashboard.scss";
// @ts-expect-error: react-hooks-use-modal needs to update the types it is exporting
import { useModal } from "react-hooks-use-modal";

const Dashboard = () => {
    const { t } = useTranslation();
    const { data, loading } = useQuery<{ viewer: Viewer }>(GET_VIEWER_INTERVIEW_SUMMARY);
    const [recentInterview, setRecentInterview] = useState<Application>();
    const [todoInterview, setTodoInterview] = useState<Application>();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const [NewCandidateModalProvider, openNewCandidateModal, closeNewCandidateModal] = useModal("root", {
        preventScroll: true,
        focusTrapOptions: {
            clickOutsideDeactivates: false,
        },
    });
    const [InterviewCompletedModalProvider, openInterviewCompletedModal, closeInterviewCompletedModal] = useModal(
        "root",
        {
            preventScroll: true,
            focusTrapOptions: {
                clickOutsideDeactivates: false,
            },
        },
    );

    useEffect(() => {
        if (location.state) {
            const pageState = location.state as { emailVerified: boolean };
            if (pageState.emailVerified) {
                openNewCandidateModal();
                window.history.replaceState({}, "");
            }
        }
        const state = searchParams.get("state");
        if (state === PageState.INTERVIEW_COMPLETED) {
            openInterviewCompletedModal();
        }
    }, [location, searchParams]);

    useEffect(() => {
        if (!loading && data) {
            const recentInterviewData = data?.viewer.dashboardSummary?.recentInterview as Application;

            if (recentInterviewData) {
                setRecentInterview(recentInterviewData);
            }
            const todoInterviewData = data?.viewer.dashboardSummary?.todoInterview as Application;
            if (todoInterviewData) {
                setTodoInterview(todoInterviewData);
            }
        }
    }, [data, loading]);

    return (
        <>
            <Header type="LoggedIn" />
            <div className="dashboard-wrapper">
                <YourCareer recentInterview={recentInterview} todoInterview={todoInterview} loading={loading} />
                <YourInterviewsTable forDashboard />
                <LatestJobPostings />
                <LearnWithHubert />
                <div className="tip-and-guide-wrapper">
                    <div className="section">
                        <HubertTip
                            title={t("Dashboard.HubertTip.Title")}
                            subtitle={t("Dashboard.HubertTip.Subtitle")}
                            description={t("Dashboard.HubertTip.Description")}
                            btnText={t("Dashboard.HubertTip.ButtonText_ReadMoreTips")}
                        />
                    </div>
                    <div className="section">
                        <ArticleLinkCard />
                    </div>
                </div>
            </div>
            <Footer />
            <NewCandidateModalProvider>
                <NewCandidateModalPopup close={closeNewCandidateModal} />
            </NewCandidateModalProvider>
            <InterviewCompletedModalProvider>
                <InterviewCompletedModal close={closeInterviewCompletedModal} />
            </InterviewCompletedModalProvider>
        </>
    );
};

export default Dashboard;
