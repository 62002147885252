import { CheckCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button, Heading, Modal, Text } from "../elements";

interface NewCandidateModalPopupProps {
    close: () => void;
}

const NewCandidateModalPopup = ({ close }: NewCandidateModalPopupProps) => {
    const { t } = useTranslation();

    return (
        <Modal close={close} className="new-candidate-modal-popup">
            <div className="head-section">
                <div className="icon-wrapper">
                    <CheckCircle size={32} />
                </div>
                <Heading level="h2">{t("Dashboard.NewCandidateModalPopup.Title")}</Heading>
            </div>
            <div className="body-section">
                <Text block>{t("Dashboard.NewCandidateModalPopup.DescriptionLine1")}</Text>
                <Text block>{t("Dashboard.NewCandidateModalPopup.DescriptionLine2")}</Text>
            </div>
            <div className="button-section">
                <Button onClick={close}>{t("Dashboard.NewCandidateModalPopup.ButtonText_LetsGo")}</Button>
            </div>
        </Modal>
    );
};

export default NewCandidateModalPopup;
