import { ReactNode } from "react";

interface ToolTipProps {
    message: string;
    children: ReactNode;
    align?: "items-start" | "items-center" | "items-end";
}

const ToolTip = ({ message, align = "items-center", children }: ToolTipProps) => (
    <div className={`tool-tip wrapper group ${align}`}>
        {children}
        <div className="tool-tip message-container">
            <span className="tool-tip message">{message}</span>
        </div>
    </div>
);

export default ToolTip;
