import type { ReactNode } from "react";
interface ConditionalComponentProps {
    condition: boolean;
    wrapper: (children: ReactNode) => ReactNode;
    children: ReactNode;
}

const ConditionalComponent = ({ condition, wrapper, children }: ConditionalComponentProps) => {
    return condition ? wrapper(children) : children;
};

export default ConditionalComponent;
