import { YourInterviewsTable } from "../../components/common";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import YourInterviewsSection from "../../components/yourInterviews/YourInterviewsSection";
import "./YourInterviews.scss";

const YourInterviews = () => (
    <>
        <Header type="LoggedIn" />
        <div className="your-interviews-wrapper">
            <YourInterviewsSection />
            <YourInterviewsTable />
        </div>
        <Footer />
    </>
);

export default YourInterviews;
