import { useMutation, useQuery } from "@apollo/client";
import { useRollbar } from "@rollbar/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { toast } from "react-toastify";
import Illustration from "../../assets/images/illustration-unsubscribe.svg";
import { UNSUBSCRIBE_REMINDER } from "../../behavior/mutations/invitation.mutation";
import { GET_UNSUBSCRIBE_REMINDER_INFO } from "../../behavior/queries/invitation.query";
import { isLogged } from "../../behavior/security/auth.service";
import { Heading } from "../../components/elements";
import Header from "../../components/header/Header";
import ReasonsWrapper, { initialValues } from "../../components/unsubscribeFromEmails/ReasonsWrapper";
import { REASONS } from "../../components/unsubscribeFromEmails/constants";
import TransitionPage from "../../pages/transition/TransitionPage";
import { Invitation } from "../../types/invitation";
import "./UnsubscribeFromEmails.scss";

const UnsubscribeFromEmails = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const { t } = useTranslation();
    const rollbar = useRollbar();
    const [isUnsubscribed, setIsUnsubscribed] = useState<boolean>(false);
    const { data, loading } = useQuery<{ invitation: Invitation }>(GET_UNSUBSCRIBE_REMINDER_INFO, {
        variables: {
            token,
        },
    });
    const [unsubscribeFromReminders] = useMutation<{
        unsubscribeReminder: boolean;
    }>(UNSUBSCRIBE_REMINDER);

    useEffect(() => {
        if (!loading) {
            if (data?.invitation.unsubscribed) {
                setIsUnsubscribed(true);
            }
        }
    }, [data, loading]);

    const handleOnSubmit = async ({ reason, yourReason }: typeof initialValues) => {
        const unsubscribeInput = {
            token,
            reason,
        };

        try {
            const response = await unsubscribeFromReminders({
                variables:
                    reason !== REASONS.OTHER
                        ? { unsubscribeInput }
                        : {
                              unsubscribeInput: {
                                  ...unsubscribeInput,
                                  otherReason: yourReason,
                              },
                          },
            });

            if (response.data) {
                const { unsubscribeReminder } = response.data;
                if (unsubscribeReminder) {
                    setIsUnsubscribed(unsubscribeReminder);
                } else {
                    toast.error<string>(t("UnsubscribeFromEmails.Error_ServerIssue"));
                }
            }
        } catch (error) {
            rollbar.error("Error unsubscribing application", [error], { token });
            toast.error<string>(t("UnsubscribeFromEmails.Error_ServerIssue"));
        }
    };

    return (
        <>
            {isLogged() ? <Header type="LoggedIn" /> : <Header />}
            <div className="unsubscribe-wrapper">
                {!loading ? (
                    <>
                        <Heading level="h3" light>
                            {!isUnsubscribed ? t("UnsubscribeFromEmails.Title") : data?.invitation.job.title}
                        </Heading>
                        <img src={Illustration} className="illustration" />
                        <Heading level="h5">
                            {!isUnsubscribed
                                ? `${t("UnsubscribeFromEmails.Subtitle")} ${data?.invitation.job.title}`
                                : t("UnsubscribeFromEmails.Success_Title")}
                        </Heading>
                        {!isUnsubscribed && <ReasonsWrapper handleOnSubmit={handleOnSubmit} />}
                    </>
                ) : (
                    <TransitionPage title="My Hubert" subtitle={t("UnsubscribeFromEmails.Loading")} />
                )}
            </div>
        </>
    );
};

export default UnsubscribeFromEmails;
