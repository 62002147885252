import { useQuery } from "@apollo/client";
import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useBreakpoint from "use-breakpoint";
import { GET_VIEWER_APPLICATIONS } from "../../../behavior/queries/application.query";
import { PageRoute } from "../../../constants";
import Application from "../../../types/application";
import { Viewer } from "../../../types/viewer";
import { BREAKPOINTS } from "../../../utils/constants/breakpoints";
import { Alert, Button, Heading, HorizontalLine } from "../../elements";
import SkeletonLoader from "../../elements/SkeletonLoader";
import InterviewBox from "./InterviewBox";
import InterviewsTable from "./InterviewsTable";

interface YourInterviewsTableProps {
    forDashboard?: boolean;
}

const YourInterviewsTable = ({ forDashboard = false }: YourInterviewsTableProps) => {
    const { t } = useTranslation();
    const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");
    const isLayoutChange = breakpoint === "sm" || breakpoint === "md";
    const { data, loading } = useQuery<{ viewer: Viewer }>(GET_VIEWER_APPLICATIONS, {
        variables: {
            limit: forDashboard ? 4 : 1000,
            skip: 0,
        },
    });
    const [yourInterviews, setYourInterviews] = useState<Application[]>([]);

    const NoInterviews = (
        <div className="mt-2">
            <Alert type="info">{t("Common.MyInterviews.NoInterviews_Message")}</Alert>
        </div>
    );

    useEffect(() => {
        if (!loading && data) {
            const yourInterviewsData = data.viewer.applications?.nodes as Application[];

            if (yourInterviewsData) {
                setYourInterviews(yourInterviewsData);
            }
        }
    }, [data, loading]);

    return (
        <div className="your-interviews-table-wrapper">
            <div className="title-section">
                <Heading level="h5">{t("Common.MyInterviews.Title")}</Heading>
                {forDashboard && (
                    <Button size="small" secondary link to={PageRoute.INTERVIEWS}>
                        <div>{t("Common.MyInterviews.ButtonText_SeeAllInterviews")}</div>
                        <ArrowRight size={20} className="ml-1.5" />
                    </Button>
                )}
            </div>
            <HorizontalLine weight="semi-dark" />
            {loading && <SkeletonLoader count={3} />}
            {!isLayoutChange && !loading && yourInterviews.length > 1 ? (
                <InterviewsTable yourInterviews={yourInterviews} loading={loading} />
            ) : (
                yourInterviews &&
                !loading &&
                yourInterviews.map((row) => <InterviewBox key={row.id} row={row} loading={loading} />)
            )}
            {!loading && yourInterviews.length === 0 && NoInterviews}
        </div>
    );
};

export default YourInterviewsTable;
