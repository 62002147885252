import { ApolloError, useMutation } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import client from "../../behavior/apolloClient";
import { BooleanResponse } from "../../behavior/graphTypes/commonResponse";
import { useAppDispatch } from "../../behavior/hooks";
import { SIGNOUT_USER } from "../../behavior/mutations/auth.mutation";
import { EXCHANGE_IMPERSONATE_TOKEN } from "../../behavior/queries/impersonation.query";
import { setSession } from "../../behavior/reducers/sessionSlice";
import { LargeAlert } from "../../components/elements";
import { LargeAlertProps } from "../../components/elements/LargeAlert";
import { PageRoute } from "../../constants";
import useReadLocalStorage from "../../helpers/hooks/useReadLocalStorage";
import { TokenModel } from "../../types/authentication";

const Impersonation = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const shouldExecute = useRef(true);
    const dispatch = useAppDispatch();
    const [logoutUser] = useMutation<BooleanResponse>(SIGNOUT_USER);
    const candidateToken = useReadLocalStorage<string>("candidate-hub-token", {
        parse: false,
    });
    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: t("ImpersonationPage.loading-title"),
        subtitle: t("ImpersonationPage.loading-subtitle"),
        type: "info",
    });

    const verify = useCallback(
        async (token: string) => {
            try {
                const response = await client.query<{
                    accessTokenResponse: TokenModel;
                }>({
                    query: EXCHANGE_IMPERSONATE_TOKEN,
                    variables: { input: { token } },
                });

                if (!response || response.error) {
                    setShowAlert({
                        title: t("AppMessages.server-error"),
                        subtitle: "",
                        type: "info",
                    });
                    return;
                } else {
                    try {
                        if (candidateToken) {
                            await logoutUser();
                        }
                    } catch {
                        // To gracefully handle error thrown due to invalid/expired existing user session
                    }

                    dispatch(setSession(response.data.accessTokenResponse));
                    navigate(PageRoute.DASHBOARD, { replace: true });
                    return;
                }
            } catch (error) {
                if (error instanceof ApolloError) {
                    setShowAlert({
                        title: "This link is broken",
                        subtitle: "",
                        type: "warning",
                    });
                }
            }
        },
        [candidateToken, dispatch, logoutUser, navigate, t],
    );

    useEffect(() => {
        if (!shouldExecute.current) {
            return;
        }
        shouldExecute.current = false;

        const token = searchParams.get("token");
        if (token) {
            verify(token);
        } else {
            navigate(PageRoute.ROOT, { replace: true });
        }
    }, [navigate, verify]);

    return (
        <div>
            <LargeAlert
                title={showAlert.title}
                subtitle={showAlert.subtitle}
                type={showAlert.type}
                button={{
                    label: t("General.Button_GoTo_MyHubert"),
                    to: PageRoute.ROOT,
                }}
            />
        </div>
    );
};
export default Impersonation;
