import { ApolloProvider } from "@apollo/client";
import { ErrorBoundary as RollbarErrorBoundary, Provider as RollbarProvider } from "@rollbar/react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from "./App";
import client from "./behavior/apolloClient";
import { store } from "./behavior/store";
import "./i18n";
import ErrorPage from "./pages/ErrorPage";
import "./styles/main.scss";
import { rollbarConfig } from "./utils/rollbarConfig";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <StrictMode>
        <RollbarProvider config={rollbarConfig}>
            <RollbarErrorBoundary
                fallbackUI={() => (
                    <ErrorPage
                        title="We encountered an issue"
                        description="We're sorry for the inconvenience. We've reported this to our analytics. In the meantime please try contacting support."
                        hideNextSteps={false}
                    />
                )}
            >
                <ApolloProvider client={client}>
                    <ReduxProvider store={store}>
                        <BrowserRouter>
                            <App />
                        </BrowserRouter>
                    </ReduxProvider>
                </ApolloProvider>
            </RollbarErrorBoundary>
        </RollbarProvider>
    </StrictMode>,
);
