import { useQuery } from "@apollo/client";
import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { GET_ARTICLE_CARD_INFO } from "../../../behavior/queries/content.query";
import { PageRoute } from "../../../constants";
import { ContentCollection } from "../../../types/content";
import { Alert, Button, Text } from "../../elements";

const AlertInfoLink = () => {
    const { t } = useTranslation();
    const { data, loading } = useQuery<{ contentCollection: ContentCollection }>(GET_ARTICLE_CARD_INFO, {
        variables: {
            candidateFilterInput: {
                tags: ["Dashboard Interview Tips"],
                optionalTags: [],
                pagination: {
                    page: 0,
                    limit: 1,
                },
            },
        },
    });

    return (
        <Alert type="info" className="custom-alert">
            <Text className="mr-2">{t("Interview.HowToImproveAtInterviews.Alert_WantMoreAdvice")}</Text>
            <Button
                secondary
                link
                className="alert-link-button mobile-text-sm"
                to={
                    !loading && data?.contentCollection.contents.length
                        ? `${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${data?.contentCollection.contents[0].id}`
                        : PageRoute.LEARN_WITH_HUBERT
                }
            >
                {t("Interview.HowToImproveAtInterviews.AlertLink_ExploreOurGeneralAdviceArticle")}
                <ArrowRight size={16} className="ml-1.5" />
            </Button>
        </Alert>
    );
};

export default AlertInfoLink;
