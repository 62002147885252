import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { jwtDecode } from "jwt-decode";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "react-toastify";
import { EmailTokenResponse, RTSTokenResponse } from "../../behavior/graphTypes/authenticationTypes";
import { BooleanResponse } from "../../behavior/graphTypes/commonResponse";
import { useAppDispatch } from "../../behavior/hooks";
import {
    SIGNUP_EMAIL_PASSWORD,
    SIGNUP_EMAIL_RTS_TOKEN,
    SIGNUP_EMAIL_TOKEN,
} from "../../behavior/mutations/auth.mutation";
import { setSession } from "../../behavior/reducers/sessionSlice";
import { LargeAlertProps } from "../../components/elements/LargeAlert";
import Link from "../../components/elements/Link";
import { ApplicationLanguage, PageRoute, PageState, TPageState } from "../../constants";
import { Invitation, InvitationTokenPayload } from "../../types/invitation";
import { User } from "../../types/user";
import { getCookie } from "../../utils/cookieHandlers";
import { registrationInviteEmailFormSchema } from "../../utils/validationSchemas";
import { Button, Text } from "../elements";
import { Password, Textbox } from "../forms";

interface RegistrationInviteEmailProps {
    setShowAlert: Dispatch<SetStateAction<LargeAlertProps>>;
    invitation?: Invitation | null;
    state: TPageState;
}

const RegistrationInviteEmailForm = ({ setShowAlert, invitation, state }: RegistrationInviteEmailProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [signUpEmailPassword, { loading: loadingSignUpPassword }] = useMutation<
        { signUpUsingPassword: BooleanResponse & { __typename: string } },
        { registerUserInput: User }
    >(SIGNUP_EMAIL_PASSWORD);

    const [signUpEmailToken, { loading: loadingSignUpToken }] = useMutation<EmailTokenResponse>(SIGNUP_EMAIL_TOKEN);

    const [signUpRTSToken, { loading: loadingSignUpRTSToken }] = useMutation<RTSTokenResponse>(SIGNUP_EMAIL_RTS_TOKEN);

    const normalSignUpButton = useMemo(() => {
        return state === PageState.INTERVIEW_COMPLETED || state === PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP;
    }, [state]);

    const signUpWithInvitationToken = async (email: string, password: string, token: string) => {
        try {
            const data = await signUpEmailToken({
                variables: {
                    registerUserByTokenInput: {
                        email,
                        password,
                        token,
                        language: getCookie("language") ?? ApplicationLanguage.EN_EU,
                    },
                },
            });
            const response = data.data?.signUpUsingToken;
            if (!response) {
                toast.error<string>(t("AppMessages.server-error"));
                return;
            }
            if (response.isError && response.errorMessage) {
                toast.error<string>(t(`AppMessages.${response.errorMessage}`));
            } else {
                dispatch(setSession(response.payload));
                if (state === PageState.SIGN_UP || state === PageState.INTERVIEW_COMPLETED) {
                    navigate(PageRoute.DASHBOARD, {
                        replace: true,
                    });
                    return;
                }
                const jwt = jwtDecode<InvitationTokenPayload>(token);

                const urlSearchParams = new URLSearchParams({
                    interviewId: jwt.interviewId,
                    token: response.payload.access_token,
                    displayName: invitation?.invitee.displayName ?? jwt.email,
                });
                navigate(`${PageRoute.PRE_INTERVIEW}?${urlSearchParams}`, {
                    replace: true,
                });
            }
        } catch (e) {
            toast.success<string>(t((e as Error).message));
        }
    };

    const signUpWithRTSToken = async (email: string, password: string, token: string) => {
        try {
            const data = await signUpRTSToken({
                variables: {
                    registerUserByTokenInput: {
                        email,
                        password,
                        token,
                        language: getCookie("language") ?? ApplicationLanguage.EN_EU,
                    },
                },
            });
            const response = data.data?.signUpUsingRTSToken;
            if (!response) {
                toast.error<string>(t("AppMessages.server-error"));
                return;
            }
            if (response.isError && response.errorMessage) {
                toast.error<string>(t(`AppMessages.${response.errorMessage}`));
            } else {
                dispatch(setSession(response.payload));
                navigate(`${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`);
            }
        } catch (e) {
            toast.success<string>(t((e as Error).message));
        }
    };

    const signUp = async (email: string, password: string) => {
        try {
            const response = await signUpEmailPassword({
                variables: {
                    registerUserInput: {
                        firstName: "",
                        lastName: "",
                        email: email,
                        password,
                        phoneNumber: "",
                        countryCode: "",
                        language: getCookie("language") ?? ApplicationLanguage.EN_EU,
                    },
                },
            });
            const type = response.data?.signUpUsingPassword.__typename;
            if (type === "BooleanResponse") {
                const booleanResponse = response.data?.signUpUsingPassword;
                if (booleanResponse?.payload) {
                    setShowAlert({
                        title: t("AppMessages.verify-email-title"),
                        subtitle: t("AppMessages.verify-email-subtitle"),
                        type: "info",
                    });
                } else if (booleanResponse?.errorMessage === "email-is-taken") {
                    toast.error<string>(t("Registration.Error_Email_Exists"));
                    return;
                }
                return;
            }
            toast.error<string>(t("AppMessages.server-error"));
        } catch (e) {
            toast.error<string>(t((e as Error).message));
        }
    };

    const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: registrationInviteEmailFormSchema,
        onSubmit: async (values) => {
            if (values) {
                const token = searchParams.get("token");
                if (token) {
                    if (state === PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP) {
                        return await signUpWithRTSToken(values.email, values.password, token);
                    } else {
                        return await signUpWithInvitationToken(values.email, values.password, token);
                    }
                } else {
                    return await signUp(values.email, values.password);
                }
            }
        },
    });

    useEffect(() => {
        const email = invitation?.invitee.email ?? searchParams.get("email");

        if (email) {
            setFieldValue("email", email);
        }
    }, [invitation, searchParams, setFieldValue]);

    const emailPasswordSet = values.email && values.password && !errors.email && !errors.password;
    const isLoading = loadingSignUpPassword || loadingSignUpToken || loadingSignUpRTSToken;
    const canSubmit = emailPasswordSet && !isLoading;

    return (
        <form className="registration-invite-email-form" onSubmit={handleSubmit}>
            <Textbox
                label={`${t("Registration.Label_Email")}*`}
                name="email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
                error={errors.email}
                touched={touched.email}
                disabled
            />
            <Password
                label={`${t("Registration.Label_Password")}*`}
                name="password"
                helpText={t("Registration.HelpText_Password")}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                error={errors.password}
                touched={touched.password}
            />
            <div className="terms-and-condition-text-wrapper">
                <Text size="small">{`${t("RegistrationInviteEmail.PrivacyPolicyText")} `}</Text>
                <Link to={"https://www.hubert.ai/candidate-privacy-policy"} target="_blank" rel="noreferrer">
                    <Text size="small" weight="semibold">
                        {t("RegistrationInviteEmail.PrivacyPolicyLink")}
                    </Text>
                    .
                </Link>
            </div>
            <Button fullWidth submit className="mt-2" disabled={!canSubmit}>
                {normalSignUpButton
                    ? t("RegistrationInviteEmail.ButtonText_SignUpNormal")
                    : t("RegistrationInviteEmail.ButtonText_SignUp")}
            </Button>
        </form>
    );
};

export default RegistrationInviteEmailForm;
