import { gql } from "@apollo/client";

export const GET_VIEWER_INFORMATION = gql`
    query Viewer {
        viewer {
            ... on Viewer {
                id
                displayName
                email
                firstName
                lastName
                phoneNumber
                notification
                providerData {
                    providerId
                    email
                }
                language
            }
            ... on RecordNotFound {
                message
            }
        }
    }
`;

export const SET_VIEWER_LANGUAGE = gql`
    mutation ViewerSetLanguage($language: String) {
        viewerSetLanguage(language: $language) {
            ... on LanguageNotSupported {
                message
            }
            ... on Viewer {
                id
                language
            }
        }
    }
`;
