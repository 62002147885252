import classNames from "classnames";
import { TFunction } from "i18next";
import { ChangeEventHandler, ComponentProps, FocusEventHandler } from "react";
import { withTranslation } from "react-i18next";
import { Text } from "../elements";
import SkeletonLoader from "../elements/SkeletonLoader";

interface DropdownProps extends ComponentProps<"select"> {
    label: string;
    helpText?: string;
    options: Array<OptionType>;
    handleChange: ChangeEventHandler<HTMLSelectElement>;
    handleBlur: FocusEventHandler<HTMLSelectElement>;
    error?: string;
    touched?: boolean;
    t: TFunction;
    loading?: boolean;
    placeholder: string;
}

type OptionType = {
    name: string;
    value: string | number;
};

const Dropdown = ({
    label,
    name,
    id = "",
    placeholder,
    helpText = "",
    options,
    handleChange,
    handleBlur,
    value,
    error = "",
    touched = false,
    t,
    loading = false,
}: DropdownProps) =>
    loading ? (
        <SkeletonLoader height="50" />
    ) : (
        <div className="form-group">
            <label htmlFor={name} className="form-label">
                {label}
            </label>
            <select
                id={id}
                onChange={handleChange}
                onBlur={handleBlur}
                name={name}
                value={value}
                className={classNames("form-select", error && touched && "error")}
            >
                <option value="" disabled>
                    {placeholder}
                </option>
                {options.map((option) => (
                    <option value={option.value} key={option.name}>
                        {option.name}
                    </option>
                ))}
            </select>
            {helpText && (
                <Text size="small" block className="help-text">
                    {helpText}
                </Text>
            )}
            {error && touched && (
                <Text size="small" block className="form-error">
                    {t(error)}
                </Text>
            )}
        </div>
    );

export default withTranslation()(Dropdown);
