import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "react-toastify";
import { BasicTokenResponse } from "../../behavior/graphTypes/authenticationTypes";
import { useAppDispatch } from "../../behavior/hooks";
import { RESET_PASSWORD, SIGNIN_EMAIL_PASSWORD } from "../../behavior/mutations/auth.mutation";
import { resetSession, setSession } from "../../behavior/reducers/sessionSlice";
import { getAccessToken } from "../../behavior/security/auth.service";
import { ApplicationError, PageRoute, PageState, ProviderId, Providers, TPageState } from "../../constants";
import { Invitation } from "../../types/invitation";
import { UserCredential } from "../../types/userCredential";
import { loginFormSchema } from "../../utils/validationSchemas";
import { Button } from "../elements";
import { LargeAlertProps } from "../elements/LargeAlert";
import { Password, Textbox } from "../forms";

interface LoginFormProps {
    setShowAlert: Dispatch<SetStateAction<LargeAlertProps>>;
    preLoadedEmail?: string | null;
    state?: TPageState | null;
    validateProvider?: (selectedProvider: string) => boolean;
    invitation?: Invitation | null;
    postLoginEvent?: (token: string, displayName: string) => void;
}

const LoginForm = ({
    setShowAlert,
    preLoadedEmail,
    state,
    validateProvider = () => true,
    postLoginEvent = () => null,
}: LoginFormProps) => {
    const { t } = useTranslation();
    const [loginEmailPassword, { loading }] = useMutation<BasicTokenResponse, UserCredential>(SIGNIN_EMAIL_PASSWORD);
    const [resetPassword, { loading: resetLoading }] = useMutation(RESET_PASSWORD);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();

    const navigate = useNavigate();
    const accessToken = getAccessToken();

    const initialValues = {
        email: preLoadedEmail || "",
        password: "",
    };

    const getProviderById = (providerId: string) => {
        switch (providerId) {
            case ProviderId.GOOGLE:
                return Providers.GOOGLE.toString();
            default:
                return "";
        }
    };

    const { values, errors, touched, handleSubmit, handleChange, handleBlur, setFieldValue, getFieldProps } = useFormik(
        {
            enableReinitialize: true,
            initialValues,
            validationSchema: loginFormSchema,
            onSubmit: async (props) => {
                if (validateProvider && validateProvider(ProviderId.PASSWORD)) {
                    try {
                        const data = await loginEmailPassword({
                            variables: { email: props!.email, password: props!.password },
                        });
                        const response = data.data?.signInUsingPassword;

                        if (!response) {
                            toast.error<string>(t("AppMessages.server-error"));
                            return;
                        }
                        if (response.isError && response.errorMessage) {
                            if (response.errorMessage === ApplicationError.EMAIL_NOT_VERIFIED) {
                                setShowAlert({
                                    title: t(`AppMessages.${response.errorMessage}-title`),
                                    subtitle: t(`AppMessages.${response.errorMessage}-subtitle`),
                                    type: "warning",
                                });
                                return;
                            }
                            toast.error<string>(t(`AppMessages.${response.errorMessage}`));
                        } else if (response.payload?.access_token) {
                            dispatch(setSession(response.payload));

                            if (
                                postLoginEvent &&
                                (state === PageState.INVITATION_SIGN_IN ||
                                    state === PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN)
                            ) {
                                postLoginEvent(response.payload.access_token, props!.email);
                                return;
                            }

                            if (state === PageState.VERIFIED_EMAIL) {
                                navigate(PageRoute.TRANSITION_PAGE, {
                                    replace: true,
                                    state: {
                                        redirectUrl: PageRoute.DASHBOARD,
                                        emailVerified: true,
                                    },
                                });
                                return;
                            }

                            let redirectUrl = PageRoute.DASHBOARD.toString();

                            if (
                                state === PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN ||
                                state === PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP
                            ) {
                                redirectUrl = redirectUrl.concat(`?state=${PageState.INTERVIEW_COMPLETED}`);
                            }

                            const redirectParam = searchParams.get("redirectUrl");
                            if (redirectParam) {
                                redirectUrl = redirectParam;
                            }

                            navigate(PageRoute.TRANSITION_PAGE, {
                                replace: true,
                                state: { redirectUrl },
                            });
                        } else {
                            toast.error<string>(t("AppMessages.invalid-credentials"));
                        }
                    } catch (e) {
                        const message = e instanceof Error ? e.message : "Unknown error";
                        toast.error<string>(message);
                    }
                }
            },
        },
    );

    useEffect(() => {
        const userEmail = searchParams.get("email");
        if (state === PageState.PASSWORD_RESET && userEmail) {
            setFieldValue("email", userEmail);
            toast.success<string>(t("AppMessages.password-reset"));
            toast.info<string>(t("AppMessages.re-enter-new-password"));
        }
    }, [searchParams, setFieldValue, t, state]);

    useEffect(() => {
        if (state === PageState.VERIFIED_EMAIL) {
            toast.success<string>(t("AppMessages.email-verified"));
            toast.info<string>(t("AppMessages.re-enter-password"));
        } else if (state === PageState.EMAIL_PREVIOUSLY_VERIFIED) {
            toast.info<string>(t("AppMessages.re-enter-password"));
        }
    }, [state, t]);

    useEffect(() => {
        if (state === PageState.SESSION_EXPIRED) {
            dispatch(resetSession());
        }
    }, [state, dispatch]);

    useEffect(() => {
        if (preLoadedEmail) setFieldValue("email", preLoadedEmail);
    }, [setFieldValue, preLoadedEmail]);

    useEffect(() => {
        if (
            accessToken &&
            state !== PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN &&
            state !== PageState.INVITATION_SIGN_IN
        ) {
            navigate(PageRoute.TRANSITION_PAGE, {
                replace: true,
                state: { redirectUrl: PageRoute.DASHBOARD },
            });
        }
    }, [state, accessToken, navigate]);

    const initiatePasswordReset = async () => {
        const emailValue = getFieldProps("email");
        if (emailValue.value) {
            const variables: { resetPasswordInput: { email: string; redirectUrl?: string } } = {
                resetPasswordInput: { email: emailValue.value },
            };

            if (state === PageState.INVITATION_SIGN_IN && state && PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN) {
                const current = new URL(window.location.href);
                variables.resetPasswordInput.redirectUrl = current.toString();
            }

            const response = await resetPassword({
                variables,
            });

            const type = response.data.resetPassword.__typename;
            if (type === "PasswordNotSet") {
                const { message } = response.data.resetPassword;
                toast.warn(`${t("ForgotPassword.PasswordNotSetAlert_Message")} ${getProviderById(message)}`);
                return;
            }

            setShowAlert((prevState) => ({
                ...prevState,
                title: t("ForgotPassword.ResetLinkAlert_Title"),
                subtitle: t("ForgotPassword.ResetLinkAlert_Subtitle"),
            }));
        } else {
            toast.error<string>(t("Login.Error_Email_Required"));
        }
    };

    return (
        <form className="login-form" onSubmit={handleSubmit}>
            <Textbox
                label={t("Login.Label_Email")}
                name="email"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
                error={errors.email}
                touched={touched.email}
                disabled={!!preLoadedEmail}
            />
            <Password
                label={t("Login.Label_Password")}
                name="password"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.password}
                error={errors.password}
                touched={touched.password}
            />
            <Button disabled={loading || resetLoading} fullWidth submit className="mt-2">
                {t("Login.ButtonText_SignIn")}
            </Button>
            <div className="forgot-password-link-wrapper">
                <Button link secondary disabled={loading || resetLoading} onClick={initiatePasswordReset}>
                    {t("Login.ButtonText_ForgotPassword")}
                </Button>
            </div>
        </form>
    );
};

export default LoginForm;
