import { useTranslation } from "react-i18next";

const OrHorizontalLine = () => {
    const { t } = useTranslation();

    return (
        <div className="or-section">
            <div className="or-seperator-line" />
            <div className="or-text">{t("General.OrText")}</div>
        </div>
    );
};

export default OrHorizontalLine;
