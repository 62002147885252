import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ErrorLink } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { RestLink } from "apollo-link-rest";
import Rollbar from "rollbar";
import { PageState } from "../constants";
import { TokenModel } from "../types/authentication";
import { getCookie } from "../utils/cookieHandlers";
import { rollbarConfig } from "../utils/rollbarConfig";
import { RefreshTokenResponse } from "./graphTypes/authenticationTypes";
import { REFRESH_TOKEN } from "./mutations/auth.mutation";
import { setSession } from "./reducers/sessionSlice";
import {
    clearTokenFromStorage,
    getAccessToken,
    isProtected,
    isTokenExpiring,
    storeTokens,
} from "./security/auth.service";

const rollbar = new Rollbar(rollbarConfig);
const httpLink = createHttpLink({
    uri: `${import.meta.env.VITE_HUB_API_URL}/graphql`,
});

const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 3,
        retryIf: (error, _operation) => !!error,
    },
});

const errorLink = new ErrorLink(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
        if (graphQLErrors[0].message.startsWith("user-not-authorized")) {
            rollbar.debug("[GraphQL errors]:", operation.operationName, operation.variables, graphQLErrors);
        } else {
            rollbar.error("[GraphQL errors]:", operation.operationName, operation.variables, graphQLErrors);
        }
    } else if (networkError) {
        if (networkError?.message === PageState.SESSION_EXPIRED) {
            rollbar.debug("[Network error]:", operation.operationName, operation.variables, networkError);
        } else {
            rollbar.error("[Network error]:", operation.operationName, operation.variables, networkError);
        }
    }
});

const getNewToken = () =>
    new Promise<TokenModel>((resolve, reject) => {
        const token: string = localStorage.getItem("candidate-hub-refresh-token") ?? "";
        const tokenClient = new ApolloClient({
            uri: `${import.meta.env.VITE_HUB_API_URL}/graphql`,
            cache: new InMemoryCache({ resultCaching: false }),
        });

        tokenClient
            .mutate<RefreshTokenResponse>({
                mutation: REFRESH_TOKEN,
                variables: { refreshToken: token },
            })
            .then((response) => {
                const data = response.data?.renewIdToken;
                if (data) {
                    if (!data.isError && data.payload) {
                        resolve(data.payload);
                    } else {
                        reject("session-expired");
                    }
                }
            })
            .catch((e) => {
                rollbar.error("Error while refreshing token", e);
                reject("Error while refreshing token");
            });
    });

const cache = new InMemoryCache({
    typePolicies: {
        Viewer: {
            fields: {
                recommendedJobs: {
                    keyArgs: ["skip", "limit"],
                },
            },
        },
    },
});

const refreshToken = (operationName: string | undefined) =>
    new Promise<string>((resolve, reject) => {
        if (!isProtected(operationName)) {
            resolve("");
        } else {
            const prevAccessToken = getAccessToken();
            if (prevAccessToken) {
                if (isTokenExpiring(prevAccessToken)) {
                    getNewToken()
                        .then((payload) => {
                            storeTokens(payload);
                            setSession(payload);
                            resolve(payload.access_token);
                        })
                        .catch((e) => {
                            rollbar.debug(e);
                            clearTokenFromStorage();
                            window.location.replace(`${window.location.origin}?state=${PageState.SESSION_EXPIRED}`);
                            reject(new Error("session-expired"));
                        });
                } else {
                    resolve(prevAccessToken);
                }
            } else {
                clearTokenFromStorage();
                window.location.replace(`${window.location.origin}?state=${PageState.SESSION_EXPIRED}`);
            }
        }
    });

const authLink = setContext(async (_, { headers }) => {
    const token = await refreshToken(_.operationName);
    return {
        headers: {
            ...headers,
            authorization: token === "" ? null : `Bearer ${token}`,
            "content-language": getCookie("preferred-language"),
        },
    };
});

const restLink = new RestLink({
    uri: `${import.meta.env.VITE_HUB_API_URL}/rest`,
});

const client = new ApolloClient({
    link: from([errorLink, authLink, restLink, retryLink, httpLink]),
    cache,
});

export default client;
