import { gql } from "@apollo/client";

export const VIEWER_PROFILE_UPDATE = gql`
    mutation ViewerProfileUpdate($profileUpdateInput: ProfileUpdateInput) {
        viewerProfileUpdate(profileUpdateInput: $profileUpdateInput) {
            id
            firstName
            lastName
            displayName
            email
            phoneNumber
            providerData {
                providerId
                email
            }
        }
    }
`;

export const VIEWER_PROFILE_CHANGE_PASSWORD = gql`
    mutation ViewerChangePassword($changePasswordInput: ChangePasswordInput!) {
        viewerChangePassword(changePasswordInput: $changePasswordInput) {
            ... on AccessTokenResponse {
                access_token
                refresh_token
                token_type
                expires_in
            }
            ... on InvalidPassword {
                message
            }
        }
    }
`;

export const VIEWER_PROFILE_LINK_PASSWORD = gql`
    mutation ViewerLinkPassword($linkPasswordInput: LinkPasswordInput!) {
        viewerLinkPassword(linkPasswordInput: $linkPasswordInput) {
            ... on AccessTokenResponse {
                access_token
                refresh_token
                token_type
                expires_in
            }
            ... on OldCredential {
                message
            }
        }
    }
`;

export const VIEWER_OPENED_DASHBOARD = gql`
    mutation VIEWER_OPENED_DASHBOARD($applicationId: String) {
        viewerOpenedDashboard(applicationId: $applicationId)
    }
`;
