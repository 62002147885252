import { useQuery } from "@apollo/client";
import { ArrowLeft } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { GET_APPLICATION_DETAIL } from "../../behavior/queries/application.query";
import { Button } from "../../components/elements";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import InterviewDetails, { InterviewDetailsProps } from "../../components/interview/InterviewDetails";
import HowToImproveAtInterviews from "../../components/interview/howToImproveAtInterviews/HowToImproveAtInterviews";
import InterviewNotFound from "../../components/interview/interviewNotFound/InterviewNotFound";
import ScriptFromInterview from "../../components/interview/scriptFromInterview/ScriptFromInterview";
import YourScore, { YourScoreProps } from "../../components/interview/yourScore/YourScore";
import { AssessedMatch, PageRoute, ProfessionalExperience } from "../../constants";
import Application, { Feedback } from "../../types/application";
import "./Interview.scss";

const Interview = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { data, loading, error } = useQuery<{
        application: Application;
    }>(GET_APPLICATION_DETAIL, {
        variables: { applicationId: id },
        skip: !id,
    });

    const [interviewDetail, setInterviewDetail] = useState<InterviewDetailsProps>();
    const [interviewScore, setInterviewScore] = useState<YourScoreProps>({
        assessedMatch: AssessedMatch.OK_MATCH,
        professionalExperience: ProfessionalExperience.OK_MATCH,
        qualified: false,
    });
    const [interviewFeedback, setInterviewFeedback] = useState<Feedback[]>([]);
    const [interviewId, setInterviewId] = useState<string>("");
    const [isLegacyInterview, setIsLegacyInterview] = useState<boolean>(false);

    useEffect(() => {
        if (!loading && data?.application) {
            const { application } = data;
            if (application.id) {
                if (application.job) {
                    const detail: InterviewDetailsProps = {
                        jobTitle: application.job.title,
                        company: application.job.company,
                        ...(application.completedAt && {
                            dateTime: application.completedAt,
                        }),
                    };

                    const scoreDetail: YourScoreProps = {
                        qualified: application.qualified,
                        assessedMatch: AssessedMatch[application.assessedMatch],
                        professionalExperience: ProfessionalExperience[application.professionalExperience],
                    };

                    if (application.interview) {
                        setInterviewId(application.interview.id);
                    }

                    setInterviewDetail(detail);
                    setInterviewScore(scoreDetail);
                    setInterviewFeedback(application.feedback);
                    setIsLegacyInterview(application.interview?.templateVersion !== 2);
                }
            }
        }
    }, [data, loading]);

    return (
        <>
            <Header type="LoggedIn" />
            {error || (!loading && !data?.application.id) ? (
                <InterviewNotFound />
            ) : (
                <div className="interview-wrapper">
                    <Button secondary link to={PageRoute.INTERVIEWS} className="back-button">
                        <ArrowLeft size={20} className="mr-2" />
                        <div>{t("Interview.ButtonText_SeeAllInterviews")}</div>
                    </Button>
                    <InterviewDetails
                        jobTitle={interviewDetail?.jobTitle}
                        company={interviewDetail?.company}
                        dateTime={interviewDetail?.dateTime}
                        loading={loading}
                    />
                    <YourScore
                        professionalExperience={interviewScore.professionalExperience}
                        assessedMatch={interviewScore.assessedMatch}
                        qualified={interviewScore.qualified}
                        loading={loading}
                    />
                    {!loading && (
                        <ScriptFromInterview interviewId={interviewId} isLegacyInterview={isLegacyInterview} />
                    )}
                    <div className="gray-bg-wrapper">
                        <HowToImproveAtInterviews
                            scoreType={interviewScore.assessedMatch}
                            questions={interviewFeedback.length > 0}
                            feedbacks={interviewFeedback}
                            loading={loading}
                        />
                    </div>
                </div>
            )}
            <Footer />
        </>
    );
};

export default Interview;
