import { useTranslation } from "react-i18next";

export const RegistrationOrLine = () => {
    const { t } = useTranslation();
    return (
        <div className="or-section">
            <div className="or-seperator-line" />
            <div className="or-text">{t("Registration.OrSeparator")}</div>
        </div>
    );
};
