import { Base64 } from "js-base64";

export function serialize<T>(data: T) {
    const json = JSON.stringify(data);
    const serializedData = Base64.encode(json);
    return serializedData;
}

export function deserialize<T>(data: string) {
    const deserializedData = Base64.decode(data);
    const parsedData: T = JSON.parse(deserializedData);
    return parsedData;
}

const useDataSerializer = () => {
    return {
        serialize,
        deserialize,
    };
};

export default useDataSerializer;
