import { AlertCircle } from "lucide-react";
import { ElementType, ReactNode } from "react";
import HubertLogo from "../../assets/images/hubert-logo.png";

interface CommonErrorPageProps {
    title: string;
    description: string;
    Icon?: ElementType;
}
type ErrorPageWithoutChildren = CommonErrorPageProps & {
    children?: never;
    hideNextSteps: false;
};

type ErrorPageWithChildren = CommonErrorPageProps & {
    hideNextSteps: true;
    children: ReactNode;
};

type ErrorPageProps = ErrorPageWithoutChildren | ErrorPageWithChildren;

const ErrorPage = ({ title, description, hideNextSteps, Icon = AlertCircle, children }: ErrorPageProps) => {
    return (
        <div className="p-6 md:p-0 max-h-screen overflow-hidden">
            <div className="-mt-24 flex flex-col justify-center gap-4 h-[100dvh] overflow-hidden mx-auto max-w-[65ch]">
                <div className="text-start flex flex-col items-start justify-start gap-2 md:gap-4">
                    {Icon && <Icon className="h-12 w-12" />}
                    <h1 className="text-4xl md:text-5xl">{title}</h1>
                    <p>{description}</p>
                </div>
                <div>
                    <p>If you think we got it wrong, try and:</p>
                    {!hideNextSteps ? (
                        <ul className="list-disc pl-8">
                            <li>
                                <span
                                    onClick={() => window.location.reload()}
                                    className="cursor-pointer text-violet underline"
                                >
                                    Reload the page
                                </span>{" "}
                                and see if that fixes it
                            </li>
                            <li>
                                Go back to the{" "}
                                <a href={`${window.location.origin}/`} className="text-violet underline">
                                    home page
                                </a>
                                .
                            </li>
                        </ul>
                    ) : (
                        children
                    )}
                </div>
            </div>
            <img
                className="absolute bottom-0 left-1/2 right-1/2 w-32 -translate-x-1/2 -translate-y-1/2"
                src={HubertLogo}
                alt="Hubert Logo"
            />
        </div>
    );
};

export default ErrorPage;
