import { AlertCircle, AlertTriangle, CheckCircle, Info } from "lucide-react";
import { ReactNode } from "react";

interface AlertProps {
    type: "loading" | "success" | "info" | "warning" | "danger";
    children: ReactNode;
    className?: string;
}

const Alert = ({ type, className = "", children }: AlertProps) => {
    const getIcon = () => {
        switch (type) {
            case "success":
                return <CheckCircle size={24} />;
            case "info":
                return <Info size={24} />;
            case "warning":
                return <AlertTriangle size={24} />;
            case "danger":
                return <AlertCircle size={24} />;
            default:
                return "";
        }
    };

    return (
        <div className={`alert-wrapper ${type} ${className}`}>
            <div className="content-wrapper">
                {getIcon()}
                <div className="content">{children}</div>
            </div>
        </div>
    );
};

export default Alert;
