import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../behavior/hooks";
import { VIEWER_PROFILE_CHANGE_PASSWORD, VIEWER_PROFILE_LINK_PASSWORD } from "../../behavior/mutations/viewer.mutation";
import { setSession } from "../../behavior/reducers/sessionSlice";
import { getAccessToken } from "../../behavior/security/auth.service";
import { PageState, ProviderId } from "../../constants";
import { JwtModel, TokenModel } from "../../types/authentication";
import { encodeParam, generateGoogleSignInUrl } from "../../utils/urlUtility";
import { getChangePasswordSchema } from "../../utils/validationSchemas";
import { Alert, Button, HorizontalLine, Text } from "../elements";
import { Password } from "../forms";

interface ChangePasswordProps {
    existingPassword: boolean;
    primaryProvider?: string;
    loading: boolean;
    refetch: () => Promise<void>;
}

const ButtonType = {
    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    SET_PASSWORD: "SET_PASSWORD",
    SIGNIN_AGAIN: "SIGNIN_AGAIN",
} as const;

type TButtonType = (typeof ButtonType)[keyof typeof ButtonType];

interface InvalidPassword {
    message?: string;
}

interface OldCredential {
    message?: string;
}

const ChangePassword = ({ loading, existingPassword, primaryProvider = "", refetch }: ChangePasswordProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const initialValues = {
        currentPassword: "",
        newPassword: "",
        repeatNewPassword: "",
    };

    const [changeUserPassword, { loading: changePasswordLoading }] = useMutation<{
        viewerChangePassword: TokenModel;
    }>(VIEWER_PROFILE_CHANGE_PASSWORD);

    const [linkUserPassword, { loading: linkPasswordLoading }] = useMutation<{
        viewerLinkPassword: TokenModel;
    }>(VIEWER_PROFILE_LINK_PASSWORD);

    const isOldSession = () => {
        const accessToken = getAccessToken();
        if (accessToken) {
            const decodedToken = jwtDecode<JwtModel>(accessToken);
            const authValidTime = decodedToken.auth_time + 3 * 60;
            const current = Math.floor(new Date().getTime() / 1000.0);
            return current > authValidTime;
        }
        return false;
    };

    const [buttonType, setButtonType] = useState<TButtonType>(
        isOldSession() ? ButtonType.SIGNIN_AGAIN : ButtonType.SET_PASSWORD,
    );

    useEffect(() => {
        if (existingPassword) {
            setButtonType(ButtonType.CHANGE_PASSWORD);
        }
    }, [existingPassword]);

    const googleSignIn = (loginState: string) => {
        const googleSignInUrl = generateGoogleSignInUrl(loginState);
        window.location.href = googleSignInUrl.toString();
    };

    const redirectUserToSignIn = () => {
        const loginState = encodeParam({ action: PageState.LINK_PASSWORD });
        switch (primaryProvider) {
            case ProviderId.GOOGLE:
                googleSignIn(loginState);
                break;
            default:
                toast.info<string>("Unsupported provider");
                break;
        }
    };

    const linkPassword = async (newPassword: string) => {
        const data = await linkUserPassword({
            variables: {
                linkPasswordInput: {
                    newPassword: newPassword,
                },
            },
        });

        const oldCredential = data.data as {
            viewerLinkPassword: OldCredential;
        };
        if (oldCredential.viewerLinkPassword.message) {
            window.location.reload();
            return;
        }

        const response = data.data as { viewerLinkPassword: TokenModel };
        if (!response.viewerLinkPassword.access_token) {
            toast.error<string>(t("AppMessages.server-error"));
            return;
        }
        if (data.errors && data.errors.length > 0) {
            toast.error<string>(t("AppMessages.internal-server-error"));
        } else {
            dispatch(setSession(response.viewerLinkPassword));
            await refetch();
            resetForm();
            toast.success<string>(t("MyProfile.ChangePassword.Success_Password_Changed"));
        }
    };

    const updatePassword = async (oldPassword: string, newPassword: string) => {
        const data = await changeUserPassword({
            variables: {
                changePasswordInput: {
                    oldPassword: oldPassword,
                    newPassword: newPassword,
                },
            },
        });

        const invalidPassword = data.data as {
            viewerChangePassword: InvalidPassword;
        };
        if (invalidPassword.viewerChangePassword.message) {
            const { message } = invalidPassword.viewerChangePassword;
            toast.error<string>(t(`MyProfile.ChangePassword.${message}`));
            return;
        }

        const response = data.data as { viewerChangePassword: TokenModel };
        if (!response.viewerChangePassword.access_token) {
            toast.error<string>(t("AppMessages.server-error"));
            return;
        }
        if (data.errors && data.errors.length > 0) {
            toast.error<string>(t("AppMessages.internal-server-error"));
        } else {
            dispatch(setSession(response.viewerChangePassword));
            await refetch();
            resetForm();
            toast.success<string>(t("MyProfile.ChangePassword.Success_Password_Changed"));
        }
    };

    const { values, errors, touched, isValid, handleSubmit, handleChange, handleBlur, resetForm } = useFormik({
        initialValues,
        validationSchema: buttonType !== ButtonType.SIGNIN_AGAIN ? getChangePasswordSchema(existingPassword) : null,
        onSubmit: async (values) => {
            try {
                if (isOldSession() && buttonType === ButtonType.SET_PASSWORD) {
                    window.location.reload();
                    return;
                }

                if (buttonType === ButtonType.SIGNIN_AGAIN) {
                    redirectUserToSignIn();
                    return;
                }

                if (existingPassword) {
                    return await updatePassword(values.currentPassword, values.newPassword);
                } else {
                    return await linkPassword(values.newPassword);
                }
            } catch (e) {
                toast.error<string>((e as Error).message);
            }
        },
    });

    const buttonText = () => {
        switch (buttonType) {
            case ButtonType.CHANGE_PASSWORD:
                return t("MyProfile.ChangePassword.ButtonText_UpdatePassword");
            case ButtonType.SIGNIN_AGAIN:
                return t("MyProfile.ChangePassword.ButtonText_SignInAgain");
            default:
                return t("MyProfile.ChangePassword.ButtonText_SetPassword");
        }
    };

    const disableButton = () => {
        if (buttonType === ButtonType.SIGNIN_AGAIN) {
            return false;
        }
        return !isValid || changePasswordLoading || linkPasswordLoading;
    };

    return (
        <div className="change-password-wrapper">
            <Text size="large" weight="semibold">
                {existingPassword
                    ? t("MyProfile.ChangePassword.Title")
                    : t("MyProfile.ChangePassword.Set_Password_Title")}
            </Text>
            <HorizontalLine weight="semi-dark" />
            <form className="change-password-form" onSubmit={handleSubmit}>
                {existingPassword && (
                    <Password
                        label={t("MyProfile.ChangePassword.Label_CurrentPassword")}
                        name="currentPassword"
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        value={values.currentPassword}
                        error={errors.currentPassword}
                        touched={touched.currentPassword}
                        loading={loading}
                    />
                )}
                <Password
                    label={t("MyProfile.ChangePassword.Label_NewPassword")}
                    name="newPassword"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.newPassword}
                    error={errors.newPassword}
                    touched={touched.newPassword}
                    loading={loading}
                    disabled={buttonType === ButtonType.SIGNIN_AGAIN}
                />
                <Password
                    label={t("MyProfile.ChangePassword.Label_RepeatNewPassword")}
                    name="repeatNewPassword"
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    value={values.repeatNewPassword}
                    error={errors.repeatNewPassword}
                    touched={touched.repeatNewPassword}
                    loading={loading}
                    disabled={buttonType === ButtonType.SIGNIN_AGAIN}
                />
                {buttonType === ButtonType.SIGNIN_AGAIN ? (
                    <Alert type="info" className="custom-alert">
                        <Text className="mr-2">{t("MyProfile.ChangePassword.Alert_SignInAgain")}</Text>
                    </Alert>
                ) : null}
                <Button submit className="mt-4" hidden={loading} disabled={disableButton()}>
                    {buttonText()}
                </Button>
            </form>
        </div>
    );
};

export default ChangePassword;
