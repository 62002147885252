import { t } from "i18next";

export const REASONS = {
    FOUND_NEW_JOB: "FOUND_JOB",
    NOT_INTERESTED: "NOT_INTERESTED",
    OTHER: "OTHER",
};

export const getReasonsOptions = () => [
    {
        label: t("UnsubscribeFromEmails.ReasonText1"),
        value: REASONS.FOUND_NEW_JOB,
    },
    {
        label: t("UnsubscribeFromEmails.ReasonText2"),
        value: REASONS.NOT_INTERESTED,
    },
    { label: t("UnsubscribeFromEmails.ReasonText3"), value: REASONS.OTHER },
];
