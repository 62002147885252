import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import client from "../../behavior/apolloClient";
import { SET_VIEWER_LANGUAGE } from "../../behavior/queries/viewer.query";
import { isLogged } from "../../behavior/security/auth.service";
import { ApplicationLanguage } from "../../constants";
import { deleteCookie, setCookie } from "../../utils/cookieHandlers";
import { Text } from "../elements";

interface LanguageSelectorProps {
    isMobile?: boolean;
    language?: string;
}

const options = [
    { value: ApplicationLanguage.EN_EU, label: "English" },
    { value: ApplicationLanguage.SV_SE, label: "Swedish" },
    { value: ApplicationLanguage.DE_DE, label: "German" },
    { value: ApplicationLanguage.NL_NL, label: "Dutch" },
    { value: ApplicationLanguage.PT_PT, label: "Portuguese" },
    { value: ApplicationLanguage.TR_TR, label: "Turkish" },
    { value: ApplicationLanguage.NO_NO, label: "Norwegian" },
    { value: ApplicationLanguage.FI_FI, label: "Finnish" },
];

const LanguageSelector = ({ isMobile = false, language }: LanguageSelectorProps) => {
    const { i18n } = useTranslation();
    const [setViewerLanguage] = useMutation(SET_VIEWER_LANGUAGE);
    const [selectedOption, setSelectedOption] = useState(language || i18n.language || ApplicationLanguage.EN_EU);

    useEffect(() => {
        if (language) {
            setSelectedOption(language);
        }
    }, [language]);

    useEffect(() => {
        setSelectedOption(i18n.language);
    }, [i18n.language]);

    const changeLanguage = (value: string) => {
        if (selectedOption === value) {
            return;
        }

        setSelectedOption(value);
        setCookie("language", value);
        i18n.changeLanguage(value);
        if (isLogged()) {
            setCookie("preferred-language", value);
            setViewerLanguage({ variables: { language: value } });
            client.refetchQueries({
                include: "active",
            });
        } else {
            deleteCookie("preferred-language");
        }
    };

    return (
        <div className={isMobile ? "language-mobile-wrapper" : ""}>
            {isMobile && (
                <Text size="large" weight="light" block className="mb-3">
                    Language
                </Text>
            )}
            <Select
                className="language-selector"
                classNamePrefix="custom"
                options={options}
                components={{ IndicatorSeparator: () => null }}
                isSearchable={false}
                value={options.filter((item) => item.value === selectedOption)}
                onChange={(option) => option && option.value && changeLanguage(option.value)}
            />
        </div>
    );
};

export default LanguageSelector;
