import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { ApplicationStatus, PageRoute } from "../../../constants";
import Application from "../../../types/application";
import { Button, Text, ToolTip } from "../../elements";
import SkeletonLoader from "../../elements/SkeletonLoader";

interface MostRecentInterviewProps {
    recentInterview: Application | undefined;
    loading: boolean;
}

const MostRecentInterview = ({ recentInterview, loading }: MostRecentInterviewProps) => {
    const { t } = useTranslation();

    const renderNotScoredInterviews = () => (
        <ToolTip message={t("Common.MyInterviews.TableBody_NotScored")} align="items-end">
            <Button size="small" secondary disabled>
                {t("Common.MyInterviews.ButtonText_NotScored")}
            </Button>
        </ToolTip>
    );

    return (
        <>
            {loading && (
                <div className="col-span-full mt-3">
                    <SkeletonLoader count={2} />
                </div>
            )}
            {recentInterview && !loading && (
                <div className="section-body mt-5">
                    <div className="left-body-section">
                        <Text weight="medium" block>
                            {recentInterview?.job?.company}
                        </Text>
                        <Text weight="light" block className="mt-2">
                            {recentInterview?.job?.title}
                        </Text>
                    </div>
                    <div className="right-body-section">
                        {recentInterview.interview?.status !== ApplicationStatus.SCORED ? (
                            renderNotScoredInterviews()
                        ) : (
                            <Button
                                size="small"
                                secondary
                                to={`${PageRoute.INTERVIEWS}/${recentInterview?.id}`}
                                hidden={loading}
                            >
                                {t("Dashboard.MyCareer.ButtonText_Details")}
                                <ArrowRight size={16} className="ml-1.5" />
                            </Button>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

export default MostRecentInterview;
