import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useSearchParams } from "react-router";
import { OPENED_DASHBOARD } from "../../behavior/mutations/invitation.mutation";
import { GET_INVITATION } from "../../behavior/queries/invitation.query";
import { Heading } from "../../components/elements";
import LargeAlert, { LargeAlertProps } from "../../components/elements/LargeAlert";
import { PageRoute } from "../../constants";
import { Invitation } from "../../types/invitation";
import "./ProcessInvitation.css";

const ProcessInvitation = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    const navigate = useNavigate();
    const [alertData, setAlertData] = useState<LargeAlertProps>({
        title: t("ProcessInvitation.loading-title"),
        subtitle: t("ProcessInvitation.loading-subtitle"),
        type: "info",
    });
    const { data, loading, error } = useQuery<{ invitation: Invitation }>(GET_INVITATION, {
        variables: {
            token,
        },
        fetchPolicy: "no-cache",
    });

    const [interviewExpired, setInterviewExpired] = useState(false);
    const [openedDashboard] = useMutation(OPENED_DASHBOARD, {
        variables: { token },
    });

    useEffect(() => {
        function isValid(invitation: Invitation) {
            const type = invitation.__typename;
            if (type === "Invitation") {
                if (invitation.interviewCompleted) {
                    setAlertData({
                        title: t("ProcessInvitation.InterviewCompleted"),
                        subtitle: "",
                        type: "info",
                    });
                    return false;
                } else {
                    return true;
                }
            } else {
                if (type === "InvitationExpired") {
                    setInterviewExpired(true);
                }
                setAlertData({
                    title: t(`ProcessInvitation.${type}`),
                    subtitle: "",
                    type: "info",
                });
                return false;
            }
        }

        const navigateToPreInterview = async (invitation: Invitation) => {
            if (invitation.invitee.providerData && invitation.invitee.providerData.length > 0) {
                navigate(`${PageRoute.PRE_INTERVIEW_LOGIN}?token=${token}`, {
                    replace: true,
                });
            } else {
                const urlSearchParams = new URLSearchParams({
                    interviewId: invitation.interviewId,
                    redirectUrl: `${PageRoute.POST_INTERVIEW_REGISTRATION}?token=${token}`,
                    displayName: invitation.invitee.displayName,
                });
                navigate(`${PageRoute.PRE_INTERVIEW}?${urlSearchParams}`, {
                    replace: true,
                });
            }
        };

        if (!loading) {
            if (error) {
                setAlertData({
                    title: t("AppMessages.server-error"),
                    subtitle: "",
                    type: "info",
                });
            } else if (data?.invitation && isValid(data.invitation)) {
                openedDashboard({
                    variables: { applicationId: data.invitation.applicationId },
                });
                navigateToPreInterview(data.invitation);
            }
        }
    }, [data, error, loading, token]);

    if (!token) {
        navigate(PageRoute.ROOT, { replace: true });
    }

    return (
        <div className="process-invitation-wrapper">
            {interviewExpired ? (
                <LargeAlert title={alertData.title} subtitle={alertData.subtitle} type={alertData.type}>
                    <Heading level="h4" light>
                        {t(`ProcessInvitation.InvitationExpired_Description1`)}{" "}
                        <Link to={PageRoute.INTERVIEWS} className="subtitle-link">
                            {t("General.Title_MyHubert")}
                        </Link>{" "}
                        {t(`ProcessInvitation.InvitationExpired_Description2`)}
                    </Heading>
                </LargeAlert>
            ) : (
                <LargeAlert
                    title={alertData.title}
                    subtitle={alertData.subtitle}
                    type={alertData.type}
                    button={{
                        label: t("General.Button_GoTo_MyHubert"),
                        to: PageRoute.ROOT,
                    }}
                />
            )}
        </div>
    );
};

export default ProcessInvitation;
