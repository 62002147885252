import classNames from "classnames";
import { TFunction } from "i18next";
import { Eye, EyeOff } from "lucide-react";
import { ChangeEventHandler, ComponentProps, FocusEventHandler, useState } from "react";
import { withTranslation } from "react-i18next";
import { Text } from "../elements";
import SkeletonLoader from "../elements/SkeletonLoader";

interface PasswordProps extends ComponentProps<"input"> {
    label?: string;
    helpText?: string;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleBlur: FocusEventHandler<HTMLInputElement>;
    error?: string;
    touched?: boolean;
    t: TFunction;
    loading?: boolean;
}

const Password = ({
    label = "",
    name,
    id = "",
    placeholder = "",
    helpText = "",
    handleChange,
    handleBlur,
    value,
    error = "",
    touched = false,
    t,
    loading = false,
    disabled = false,
}: PasswordProps) => {
    const [passwordVisibility, setPasswordVisibility] = useState(false);

    return loading ? (
        <SkeletonLoader height="50" />
    ) : (
        <div className="form-group form-password">
            <label htmlFor={name} className={`form-label ${!label ? "h-5" : ""}`}>
                {label}
            </label>
            <input
                id={id}
                className={classNames("form-input", error && touched && "error", disabled && "disabled")}
                type={passwordVisibility ? "text" : "password"}
                name={name}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                autoComplete="off"
                disabled={disabled}
            />
            {passwordVisibility ? (
                <Eye
                    size={20}
                    onClick={() => setPasswordVisibility((prevState) => !prevState)}
                    className="visibility-toggler-icon"
                />
            ) : (
                <EyeOff
                    size={20}
                    onClick={() => setPasswordVisibility((prevState) => !prevState)}
                    className="visibility-toggler-icon"
                />
            )}
            {helpText && (
                <Text size="small" block className="help-text">
                    {helpText}
                </Text>
            )}
            {error && touched && (
                <Text size="small" block className="form-error">
                    {t(error)}
                </Text>
            )}
        </div>
    );
};

export default withTranslation()(Password);
