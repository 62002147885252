import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MiniLogoImage from "../../../assets/images/hubert-logo-mini.png";
import { Heading, LargeAlert } from "../../../components/elements";
import { LargeAlertProps } from "../../../components/elements/LargeAlert";
import LoginForm from "../../../components/login/LoginForm";
import SocialMediaLogin from "../../../components/login/SocialMediaLogin";
import { BenefitsBox } from "../../../components/registration";
import { ProviderId, TPageState } from "../../../constants";
import { Invitation } from "../../../types/invitation";
import "../Login.scss";

interface InviteEmailSignInProps {
    invitation?: Invitation | null;
    postLoginEvent?: (token: string, displayName: string) => void;
    state: TPageState;
    email?: string;
}

const InviteEmailSignIn = ({ invitation, postLoginEvent = () => null, state, email }: InviteEmailSignInProps) => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: "",
        subtitle: "",
        type: "info",
    });

    const checkSignInProvider = (selectedProvider: string): boolean => {
        const providerData = invitation?.invitee.providerData;
        if (providerData) {
            if (providerData.length > 0) {
                const selected = providerData.find((x) => x.providerId === selectedProvider);
                if (selected) {
                    return true;
                }

                switch (providerData[0].providerId) {
                    case ProviderId.PASSWORD:
                        toast.info<string>("Please sign in using Password.");
                        break;
                    case ProviderId.GOOGLE:
                        toast.info<string>("Please sign in using Google.");
                        break;
                    default:
                        toast.info<string>("Unsupported provider");
                        break;
                }
                return false;
            }
        }
        toast.info<string>("Unsupported provider");
        return false;
    };

    return (
        <div className="login-invite-email-wrapper">
            <div className="left-section">
                <Heading level="h2" light>
                    {invitation?.job?.title}
                </Heading>
                <Heading level="h4">{t("LoginInviteEmail.Subtitle")}</Heading>
                <BenefitsBox />
            </div>
            <div className="right-section">
                <img className="hubert-logo-mini" src={MiniLogoImage} alt="Hubert logo mini" />
                <Heading level="h4">{t("Login.LoginTitle")}</Heading>
                {showAlert.title ? (
                    <LargeAlert title={showAlert.title} subtitle={showAlert.subtitle} type={showAlert.type} />
                ) : (
                    <div>
                        <LoginForm
                            setShowAlert={setShowAlert}
                            preLoadedEmail={email}
                            state={state}
                            validateProvider={invitation ? checkSignInProvider : () => true}
                            invitation={invitation}
                            postLoginEvent={postLoginEvent}
                        />
                        <div className="or-section">
                            <div className="or-seperator-line" />
                            <div className="or-text">{t("Login.OrSeparator")}</div>
                        </div>
                        <SocialMediaLogin
                            state={state}
                            validateProvider={invitation ? checkSignInProvider : () => true}
                            invitation={invitation}
                            preLoadedEmail={email}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default InviteEmailSignIn;
