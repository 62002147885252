import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import { VIEWER_OPENED_DASHBOARD } from "../../../behavior/mutations/viewer.mutation";
import { GET_VIEWER_INFORMATION } from "../../../behavior/queries/viewer.query";
import { PageRoute } from "../../../constants";
import { Viewer } from "../../../types/viewer";
import { Button } from "../../elements";
import OldInterviewRedirectModal from "./OldInterviewRedirectModal";
// @ts-expect-error: react-hooks-use-modal needs to update the types it is exporting
import { useModal } from "react-hooks-use-modal";
import { getAccessToken } from "../../../behavior/security/auth.service";

interface StartInterviewButtonProps {
    btnLabel: string;
    applicationId: string;
    interviewId: string;
    hidden?: boolean;
    oldInterviewFlow?: boolean;
}

const StartInterviewButton = ({
    btnLabel,
    applicationId,
    interviewId,
    hidden,
    oldInterviewFlow = false,
}: StartInterviewButtonProps) => {
    const [viewerOpenedDashboard] = useMutation(VIEWER_OPENED_DASHBOARD);
    const [ModalProvider, openModal, closeModal] = useModal("root", {
        preventScroll: true,
        focusTrapOptions: {
            clickOutsideDeactivates: false,
        },
    });
    const navigate = useNavigate();
    const { data } = useQuery<{ viewer: Viewer }>(GET_VIEWER_INFORMATION);

    const redirectToInterview = () => {
        const accessToken = getAccessToken();

        if (!interviewId || !accessToken) {
            return;
        }
        const displayName = data?.viewer.displayName ?? data?.viewer.email;

        const urlSearchParams = new URLSearchParams({
            interviewId: interviewId,
            token: accessToken,
            displayName: displayName ?? "",
        });
        navigate(`${PageRoute.PRE_INTERVIEW}?${urlSearchParams}`);
    };

    const startInterview = async () => {
        await viewerOpenedDashboard({
            variables: { applicationId },
        });

        if (oldInterviewFlow) {
            openModal();
        } else {
            redirectToInterview();
        }
    };

    return (
        <>
            <Button size="small" hidden={hidden && !data} onClick={async () => startInterview()}>
                {btnLabel}
            </Button>
            <ModalProvider>
                <OldInterviewRedirectModal applicationId={applicationId} interviewId={interviewId} close={closeModal} />
            </ModalProvider>
        </>
    );
};

export default StartInterviewButton;
