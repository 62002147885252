import { useTranslation } from "react-i18next";
import { Heading, Image, Text } from "../../../elements";
import { assessedMatch, professionalExperiences, qualifyingRequirements } from "./contentArrays";

const PossibleOutcomes = () => {
    const { t } = useTranslation();

    return (
        <div className="possible-outcomes-wrapper">
            <div className="assessed-match">
                <Heading level="h4" className="mb-2">
                    {t("Interview.HowHubertScoresInterviews.AssessedMatch_Title")}
                </Heading>
                <Text block className="mb-2">
                    {t("Interview.HowHubertScoresInterviews.AssessedMatch_Description")}
                </Text>
                <Text block>{t("Interview.HowHubertScoresInterviews.AssessedMatch_DescriptionSecondary")}</Text>
                <div className="scores-main-wrapper">
                    {assessedMatch.map((score) => (
                        <div className="score-wrapper" key={score.title}>
                            <div className="head-section">
                                <Image src={score.image} alt={score.imageAltText} />
                                <Text weight="semibold" size="large">
                                    {t(score.title)}
                                </Text>
                            </div>
                            <Text>{t(score.description)}</Text>
                        </div>
                    ))}
                </div>
            </div>
            <div className="profession-experience">
                <Heading level="h4" className="mb-2">
                    {t("Interview.HowHubertScoresInterviews.ProfessionalExperiences_Title")}
                </Heading>
                <Text>{t("Interview.HowHubertScoresInterviews.ProfessionalExperiences_Description")}</Text>
                <div className="scores-main-wrapper">
                    {professionalExperiences.map((score) => (
                        <div className="score-wrapper" key={score.title}>
                            <div className="head-section">
                                <Image src={score.image} alt={score.imageAltText} />
                                <Text weight="semibold" size="large">
                                    {t(score.title)}
                                </Text>
                            </div>
                            <Text>{t(score.description)}</Text>
                        </div>
                    ))}
                </div>
            </div>
            <div className="match-for-this-offer">
                <Heading level="h4" className="mb-2">
                    {t("Interview.HowHubertScoresInterviews.QualifyingRequirements_Title")}
                </Heading>
                <Text>{t("Interview.HowHubertScoresInterviews.QualifyingRequirements_Description")}</Text>
                <div className="scores-main-wrapper">
                    {qualifyingRequirements.map((score) => (
                        <div className="score-wrapper" key={score.title}>
                            <div className="head-section">
                                <Image src={score.image} alt={score.imageAltText} />
                                <Text weight="semibold" size="large">
                                    {t(score.title)}
                                </Text>
                            </div>
                            <Text>{t(score.description)}</Text>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PossibleOutcomes;
