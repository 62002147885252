import { gql } from "@apollo/client";

const GET_JOB_POSTINGS_FOR_VIEWER = gql`
    query Viewer($top: Int!) {
        viewer {
            ... on Viewer {
                id
                recommendedJobs(top: $top) {
                    company
                    title
                    url
                    locations {
                        city
                        country
                    }
                }
            }
            ... on RecordNotFound {
                message
            }
        }
    }
`;

export { GET_JOB_POSTINGS_FOR_VIEWER };
