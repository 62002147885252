import { useQuery } from "@apollo/client";
import HubertTipIcon from "../../assets/images/info-icon-violet.svg";
import { GET_ARTICLE_CARD_INFO } from "../../behavior/queries/content.query";
import { PageRoute } from "../../constants";
import { ContentCollection } from "../../types/content";
import { Button, Heading, HorizontalLine, Text } from "../elements";

interface HubertTipProps {
    title: string;
    subtitle: string;
    description: string;
    btnText: string;
}

const HubertTip = ({ title, subtitle, description, btnText }: HubertTipProps) => {
    const { data, loading } = useQuery<{ contentCollection: ContentCollection }>(GET_ARTICLE_CARD_INFO, {
        variables: {
            candidateFilterInput: {
                tags: ["Dashboard Interview Tips"],
                optionalTags: [],
                pagination: {
                    page: 0,
                    limit: 1,
                },
            },
        },
    });

    return (
        <div className="hubert-tip-wrapper">
            <div className="head-section">
                <img src={HubertTipIcon} className="hubert-tip-icon" />
                <Heading level="h4">{title}</Heading>
            </div>
            <HorizontalLine weight="semi-dark" className="mb-6" />
            <Text size="large" weight="semibold" block className="mb-5">
                {subtitle}
            </Text>
            <Text block className="mb-6">
                {description}
            </Text>
            <Button
                secondary
                to={
                    !loading && data?.contentCollection.contents.length
                        ? `${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${data?.contentCollection.contents[0].id}`
                        : PageRoute.LEARN_WITH_HUBERT
                }
            >
                {btnText}
            </Button>
        </div>
    );
};

export default HubertTip;
