import classNames from "classnames";
import { TFunction } from "i18next";
import { ChangeEventHandler, ComponentProps, FocusEventHandler } from "react";
import { withTranslation } from "react-i18next";
import SkeletonLoader from "../../components/elements/SkeletonLoader";
import { Text } from "../elements";

interface TextboxProps extends ComponentProps<"input"> {
    label?: string;
    helpText?: string;
    handleChange: ChangeEventHandler<HTMLInputElement>;
    handleBlur: FocusEventHandler<HTMLInputElement>;
    error?: string;
    touched?: boolean;
    t: TFunction;
    loading?: boolean;
}

const Textbox = ({
    type = "text",
    label = "",
    name,
    id = "",
    placeholder = "",
    helpText = "",
    disabled = false,
    handleChange,
    handleBlur,
    value,
    error = "",
    touched = false,
    t,
    loading = false,
}: TextboxProps) =>
    loading ? (
        <SkeletonLoader height="50" />
    ) : (
        <div className="form-group">
            <label htmlFor={name} className={`form-label ${!label ? "h-5" : ""}`}>
                {label}
            </label>
            <input
                id={id}
                className={classNames("form-input", error && touched && "error", disabled && "disabled")}
                type={type}
                name={name}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
                autoComplete="off"
                disabled={disabled}
            />
            {helpText && (
                <Text size="small" block className="help-text">
                    {helpText}
                </Text>
            )}
            {error && touched && (
                <Text size="small" block className="form-error">
                    {t(error)}
                </Text>
            )}
        </div>
    );

export default withTranslation()(Textbox);
