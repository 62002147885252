import { useQuery } from "@apollo/client";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "react-toastify";
import { GET_INVITATION } from "../../../behavior/queries/invitation.query";
import {
    clearTokenFromStorage,
    getAccessToken,
    getCurrentUser,
    isTokenExpiring,
} from "../../../behavior/security/auth.service";
import Header from "../../../components/header/Header";
import { PageRoute, PageState } from "../../../constants";
import { Invitation, InvitationTokenPayload } from "../../../types/invitation";
import InviteEmailSignIn from "./InviteEmailSignIn";
import "./LoginInviteEmail.scss";

const LoginInviteEmail = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const token = searchParams.get("token");
    const jwt = token ? jwtDecode<InvitationTokenPayload>(token) : null;

    const { data, loading } = useQuery<{ invitation: Invitation }>(GET_INVITATION, {
        variables: {
            token,
        },
    });

    const redirectToInterview = useCallback(
        (accessToken: string, displayName: string) => {
            if (token && jwt) {
                const urlSearchParams = new URLSearchParams({
                    interviewId: jwt.interviewId,
                    token: accessToken,
                    displayName: displayName,
                });
                navigate(`${PageRoute.PRE_INTERVIEW}?${urlSearchParams}`, {
                    replace: true,
                });
            }
        },
        [navigate, token, jwt],
    );

    useEffect(() => {
        if (token && jwt) {
            const user = getCurrentUser();
            if (user) {
                if (user.email !== jwt.email) clearTokenFromStorage();
            }
        } else {
            toast.error<string>(t("AppMessages.invalid-invitation"));
            navigate("/", { replace: true });
        }

        if (!loading) {
            const accessToken = getAccessToken();
            if (accessToken) {
                if (isTokenExpiring(accessToken)) {
                    const currentUrl = `${PageRoute.PRE_INTERVIEW_LOGIN}?token=${token}`;
                    navigate(PageRoute.TRANSITION_PAGE, {
                        state: { redirectUrl: currentUrl },
                    });
                } else {
                    if (data?.invitation.__typename === "Invitation")
                        redirectToInterview(accessToken, data?.invitation.invitee.displayName);
                    else navigate(`${PageRoute.INVITATION_VERIFICATION}?token=${token}`, { replace: true });
                }
            }
        }
    }, [data, loading, navigate, redirectToInterview, t, token, jwt]);

    const skipToPreInterview = useCallback(() => {
        if (data?.invitation && data?.invitation.__typename === "Invitation" && token && jwt) {
            const urlSearchParams = new URLSearchParams({
                interviewId: jwt.interviewId,
                redirectUrl: `${PageRoute.POST_INTERVIEW_LOGIN}?state=${PageState.INVITATION_POST_INTERVIEW_SIGN_IN}&token=${token}`,
                displayName: data.invitation.invitee.displayName,
            });
            navigate(`${PageRoute.PRE_INTERVIEW}?${urlSearchParams}`);
        } else {
            toast.info<string>("Invalid Invitation.");
        }
    }, [data?.invitation, navigate, token, jwt]);

    return (
        <>
            <Header type="SignInInviteEmail" interviewRedirect={skipToPreInterview} />
            <InviteEmailSignIn
                invitation={data?.invitation}
                state={PageState.INVITATION_SIGN_IN}
                postLoginEvent={redirectToInterview}
                email={jwt?.email}
            />
        </>
    );
};

export default LoginInviteEmail;
