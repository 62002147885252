import * as Yup from "yup";

const forgotPasswordFormSchema = Yup.object().shape({
    newPassword: Yup.string()
        .required("ForgotPassword.ResetYourPassword.Error_NewPassword_Required")
        .min(6, "ForgotPassword.ResetYourPassword.Error_Password_6_Characters"),
    confirmNewPassword: Yup.string()
        .required("ForgotPassword.ResetYourPassword.Error_ConfirmNewPassword_Required")
        .nullable()
        .oneOf([Yup.ref("newPassword"), null], "ForgotPassword.ResetYourPassword.Error_ConfirmNewPassword_NotMatch"),
});

export default forgotPasswordFormSchema;
