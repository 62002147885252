export const tabs = [
    {
        textKey: "HelpCenter.FAQSection.Tabs_All",
        value: "all",
    },
    {
        textKey: "HelpCenter.FAQSection.Tabs_AboutHubertInterview",
        value: "aboutHubertInterview",
    },
    {
        textKey: "HelpCenter.FAQSection.Tabs_TechnicalSupport",
        value: "technicalSupport",
    },
];
