import classNames from "classnames";
import { ComponentPropsWithoutRef, ReactNode } from "react";
import { Link as ReactLink } from "react-router";

interface LinkProps extends ComponentPropsWithoutRef<typeof ReactLink> {
    button?: boolean;
    native?: boolean;
    secondary?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    size?: "small" | "large" | "";
    children?: ReactNode;
}

const Link = ({
    to = "",
    className = "",
    children,
    secondary,
    fullWidth,
    disabled,
    button,
    native,
    size,
    ...linkProps
}: LinkProps) => {
    const getBtnClassname = () => {
        if (button) {
            switch (size) {
                case "small":
                    return "btn btn-sm";
                case "large":
                    return "btn btn-lg";
                default:
                    return "btn";
            }
        } else {
            switch (size) {
                case "small":
                    return "link link-sm";
                case "large":
                    return "link link-lg";
                default:
                    return "link";
            }
        }
    };

    const mergeClassNames = () => {
        return classNames(
            className,
            getBtnClassname(),
            button && "btn-anchor",
            disabled && "disabled",
            secondary && "btn-secondary",
            fullWidth && "w-full",
        );
    };

    if (native) {
        return (
            <Link className={mergeClassNames()} to={to} {...linkProps}>
                {" "}
                {children}{" "}
            </Link>
        );
    }

    return (
        <a className={mergeClassNames()} href={to.toString()} {...linkProps}>
            {children}
        </a>
    );
};

export default Link;
