import { useTranslation } from "react-i18next";
import { Button, HorizontalLine, Text } from "../../elements";
import IframeModal from "./IframeModal";
// @ts-expect-error: react-hooks-use-modal needs to update the types it is exporting
import { useModal } from "react-hooks-use-modal";

interface ScriptFromInterviewProps {
    interviewId: string;
    isLegacyInterview: boolean;
}

const ScriptFromInterview = ({ interviewId, isLegacyInterview }: ScriptFromInterviewProps) => {
    const { t } = useTranslation();
    const [ModalProvider, open, close, isOpen] = useModal("root", {
        focusTrapOptions: {
            clickOutsideDeactivates: true,
        },
    });

    return (
        <div className="script-from-interview-wrapper">
            <Text size="large" weight="semibold">
                {t("Interview.ScriptFromInterview.Title")}
            </Text>
            <HorizontalLine weight="semi-dark" />
            <div className="button-wrapper">
                <Button size="small" onClick={open} disabled={interviewId === ""}>
                    {t("Interview.ScriptFromInterview.ButtonText_SeeWholeTranscript")}
                </Button>
            </div>
            <ModalProvider>
                <IframeModal
                    isOpen={isOpen}
                    close={close}
                    interviewId={interviewId}
                    isLegacyInterview={isLegacyInterview}
                />
            </ModalProvider>
        </div>
    );
};

export default ScriptFromInterview;
