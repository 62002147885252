import { CheckCircle } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Button, Heading, Modal, Text } from "../elements";

interface InterviewCompletedModalProps {
    close: () => void;
}

const InterviewCompletedModal = ({ close }: InterviewCompletedModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal close={close} className="interview-completed-modal">
            <div className="head-section">
                <div className="icon-wrapper">
                    <CheckCircle size={32} />
                </div>
                <div>
                    <Heading level="h3">{t("Dashboard.InterviewCompletedModal.Title")}</Heading>
                    <Heading level="h4" light>
                        {t("Dashboard.InterviewCompletedModal.Subtitle")}
                    </Heading>
                </div>
            </div>
            <div className="body-section">
                <Text block>{t("Dashboard.InterviewCompletedModal.Description")}</Text>
            </div>
            <div className="button-section">
                <Button onClick={close}>{t("Dashboard.InterviewCompletedModal.ButtonText_Continue")}</Button>
            </div>
        </Modal>
    );
};

export default InterviewCompletedModal;
