import { Check } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Heading, Text } from "../elements";

type TipProps = {
    textLabel: string;
};

const Tip = ({ textLabel }: TipProps) => (
    <div className="tip-wrapper">
        <Check className="icon" />
        <Text weight="regular" className="text-sm">
            {textLabel}
        </Text>
    </div>
);

const TipBox = () => {
    const { t } = useTranslation();

    return (
        <div className="tip-box-wrapper">
            <Heading level="h5" className="text-lg">
                {t("PreInterviewPage.TipBox_Title")}
            </Heading>
            <div className="tips-wrapper">
                <Tip textLabel={t("PreInterviewPage.TipBox_Tip1")} />
                <Tip textLabel={t("PreInterviewPage.TipBox_Tip2")} />
                <Tip textLabel={t("PreInterviewPage.TipBox_Tip3")} />
                <Tip textLabel={t("PreInterviewPage.TipBox_Tip4")} />
                <Tip textLabel={t("PreInterviewPage.TipBox_Tip5")} />
                <Tip textLabel={t("PreInterviewPage.TipBox_Tip6")} />
            </div>
        </div>
    );
};

export default TipBox;
