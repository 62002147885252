import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "react-toastify";
import { SEND_BENEFITS_EMAIL } from "../../behavior/mutations/invitation.mutation";
import { GET_INVITATION } from "../../behavior/queries/invitation.query";
import Header from "../../components/header/Header";
import CompletedAlert from "../../components/interview/CompletedAlert";
import { ApplicationLanguage, PageState } from "../../constants";
import { Invitation } from "../../types/invitation";
import InviteEmailSignUp from "../registration/InviteEmailSignUp";
import "./InterviewCompletedPageInviteEmail.scss";

const InterviewCompletedPageInviteEmail = () => {
    const { t, i18n } = useTranslation();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [getInvitation] = useLazyQuery(GET_INVITATION);
    const [sendBenefitsEmail] = useMutation(SEND_BENEFITS_EMAIL);
    const [invitation, setInvitation] = useState<Invitation>();

    const verify = async (token: string): Promise<void> => {
        try {
            const response = await getInvitation({
                variables: {
                    token,
                },
                fetchPolicy: "no-cache",
            });

            if (!response) {
                toast.error<string>(t("AppMessages.server-error"));
                return;
            }

            const type = response.data.invitation.__typename;
            if (type !== "Invitation") {
                toast.error<string>(t(`ProcessInvitation.${type}`));
                navigate("/", { replace: true });
                return;
            }

            const invitationData = response.data.invitation as Invitation;
            if (invitationData.interviewCompleted) {
                await sendBenefitsEmail({
                    variables: {
                        token,
                        language: (i18n && i18n.language) ?? ApplicationLanguage.EN_EU,
                    },
                });
                setInvitation(invitationData);
            } else {
                toast.error<string>(t("InterviewCompletedPageInviteEmail.InterviewNotCompleted"));
                navigate("/", { replace: true });
            }
        } catch (e) {
            toast.error<string>(t((e as Error).message));
        }
    };

    useEffect(() => {
        const token = searchParams.get("token");
        if (token) {
            verify(token);
        } else {
            // navigate("/", { replace: true });
        }
    }, [searchParams]);

    return (
        <>
            <Header />
            <div className="interview-completed-invite-email-wrapper">
                <CompletedAlert />
                <InviteEmailSignUp interviewCompleted invitation={invitation} state={PageState.INTERVIEW_COMPLETED} />
            </div>
        </>
    );
};

export default InterviewCompletedPageInviteEmail;
