import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import germanTexts from "./locales/de-DE.json";
import englishTexts from "./locales/en-EU.json";
import finnishTexts from "./locales/fi-FI.json";
import dutchTexts from "./locales/nl-NL.json";
import norwegianTexts from "./locales/no-NO.json";
import portugueseTexts from "./locales/pt-PT.json";
import swedishTexts from "./locales/sv-SE.json";
import turkishTexts from "./locales/tr-TR.json";

i18n.use(initReactI18next).init({
    resources: {
        "en-EU": {
            translation: englishTexts,
        },
        "sv-SE": {
            translation: swedishTexts,
        },
        "de-DE": {
            translation: germanTexts,
        },
        "nl-NL": {
            translation: dutchTexts,
        },
        "tr-TR": {
            translation: turkishTexts,
        },
        "no-NO": {
            translation: norwegianTexts,
        },
        "pt-PT": {
            translation: portugueseTexts,
        },
        "fi-FI": {
            translation: finnishTexts,
        },
    },
    lng: "en-EU",
    fallbackLng: "en-EU",
    interpolation: {
        escapeValue: false,
    },
});
