import { Text } from "../../elements";

interface QuestionProps {
    question: string;
    answer: string;
    loading?: boolean;
}

const Question = ({ question, answer, loading = false }: QuestionProps) => (
    <div className="question-wrapper skeleton-loading">
        <Text weight="semibold" block html className="mb-3" loading={loading}>
            {question}
        </Text>
        <Text block html>
            {answer}
        </Text>
    </div>
);

export default Question;
