import { ArrowRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { ApplicationStatus, PageRoute } from "../../../constants";
import Application from "../../../types/application";
import { generateLiteralDay } from "../../../utils/dateUtilities";
import { Button, Text } from "../../elements";
import StartInterviewButton from "../startInterviewButton/StartInterviewButton";

interface InterviewBoxProps {
    row: Application;
    loading: boolean;
}

const InterviewBox = ({ row, loading }: InterviewBoxProps) => {
    const { t } = useTranslation();

    const interviewEnded = (() => {
        if (row.interview?.status !== ApplicationStatus.TO_DO) {
            return true;
        }
        return false;
    })();
    const prefix = interviewEnded ? null : t("Common.MyInterviews.ApplicationDate_Invited");

    return (
        <div className="interview-box-wrapper">
            <div className="first-section">
                <Text weight="medium" block>
                    {row.job?.company}
                </Text>
                <Text weight="light" block>
                    {row.job?.title}
                </Text>
                <Text size="small" block>
                    {generateLiteralDay(row.createdAt, prefix, t)}
                </Text>
                {row?.interview?.status === ApplicationStatus.NOT_SCORED && (
                    <Text className="text-gray-500">{t("Common.MyInterviews.TableBody_NotScored")}</Text>
                )}
            </div>
            <div className="second-section">
                {row.interview?.status === ApplicationStatus.TO_DO ? (
                    <StartInterviewButton
                        btnLabel={t("Common.MyInterviews.ButtonText_StartInterview")}
                        applicationId={row.id}
                        interviewId={row.interview.id}
                        hidden={loading}
                        oldInterviewFlow={row.job?.candidateApp === true}
                    />
                ) : (
                    <Button
                        size="small"
                        secondary
                        to={`${PageRoute.INTERVIEWS}/${row.id}`}
                        disabled={row.interview?.status !== ApplicationStatus.SCORED}
                    >
                        {row.interview?.status !== ApplicationStatus.SCORED
                            ? t("Common.MyInterviews.ButtonText_NotScored")
                            : t("Common.MyInterviews.ButtonText_Details")}
                        <ArrowRight size={20} className="ml-1.5" />
                    </Button>
                )}
            </div>
        </div>
    );
};

export default InterviewBox;
