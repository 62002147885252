import { useMutation } from "@apollo/client";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "react-toastify";
import { FederatedSignInTokenResponse } from "../../behavior/graphTypes/authenticationTypes";
import { useAppDispatch } from "../../behavior/hooks";
import { SIGNIN_FEDERATED_LOGIN } from "../../behavior/mutations/auth.mutation";
import { setSession } from "../../behavior/reducers/sessionSlice";
import LargeAlert, { LargeAlertProps } from "../../components/elements/LargeAlert";
import { ApplicationLanguage, PageRoute, PageState, ProviderId, TokenAction } from "../../constants";
import TransitionPage from "../../pages/transition/TransitionPage";
import { LoginStateWithAction } from "../../types/authentication";
import { UserCredentialFederated } from "../../types/userCredential";
import { getCookie } from "../../utils/cookieHandlers";
import { decodeStateParam } from "../../utils/urlUtility";

const GoogleCallback = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const shouldExecute = useRef(true);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [federatedSignin] = useMutation<FederatedSignInTokenResponse, UserCredentialFederated>(
        SIGNIN_FEDERATED_LOGIN,
    );
    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: t("AppMessages.sign-in-title"),
        subtitle: t("AppMessages.sign-in-subtitle"),
        type: "loading",
    });

    const exchangeGoogleIdentity = useCallback(
        async (
            code: string,
            options?: Partial<LoginStateWithAction> & {
                linkPassword?: boolean;
            },
        ): Promise<void> => {
            try {
                const postInvitationStates = [
                    PageState.INVITATION_POST_INTERVIEW_SIGN_UP,
                    PageState.INVITATION_POST_INTERVIEW_SIGN_IN,
                ] as const;
                const postJobApplyStates = [
                    PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP,
                    PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN,
                ] as const;

                const language: string = getCookie("language") ?? ApplicationLanguage.EN_EU;

                const request: UserCredentialFederated = {
                    provider: ProviderId.GOOGLE,
                    token: code,
                    language,
                };

                if (options?.invitation) {
                    request.email = options.invitation.invitee.email;
                }

                if (options?.email) {
                    request.email = options.email;
                }

                const data = await federatedSignin({
                    variables: request,
                });
                const response = data.data?.signInFederatedUser;
                if (!response) {
                    toast.error<string>(t("AppMessages.server-error"));
                    return;
                }

                if (response.isError && response.errorMessage) {
                    if (response.errorMessage === "email-mismatch") {
                        if (options?.action === PageState.INVITATION_SIGN_IN) {
                            setShowAlert({
                                title: t(`AppMessages.${response.errorMessage}-title`),
                                subtitle: t(`AppMessages.${response.errorMessage}-subtitle`),
                                type: "warning",
                                button: {
                                    label: t("SocialLoginCallback.ButtonText_TryAgain"),
                                    to: `${PageRoute.INVITATION_VERIFICATION}?token=${options?.token}`,
                                },
                            });
                        } else if (options?.invitation && postInvitationStates.find((x) => x === options?.action)) {
                            const retryUrl = new URL(PageRoute.POST_INTERVIEW_REGISTRATION, window.location.origin);

                            if (options?.token) retryUrl.searchParams.append("token", options?.token);

                            if (options?.action === PageState.INVITATION_POST_INTERVIEW_SIGN_IN) {
                                retryUrl.searchParams.append("state", options.action);
                                retryUrl.pathname = PageRoute.POST_INTERVIEW_LOGIN;
                            }

                            setShowAlert({
                                title: t(`AppMessages.${response.errorMessage}-title`),
                                subtitle: t(`AppMessages.${response.errorMessage}-subtitle`),
                                type: "warning",
                                button: {
                                    label: t("SocialLoginCallback.ButtonText_TryAgain"),
                                    to: retryUrl.pathname + "?" + retryUrl.searchParams.toString(),
                                },
                            });
                        } else if (options?.email && postJobApplyStates.find((x) => x === options?.action)) {
                            setShowAlert({
                                title: t(`AppMessages.${response.errorMessage}-title`),
                                subtitle: t(`AppMessages.${response.errorMessage}-subtitle`),
                                type: "warning",
                                button: {
                                    label: t("SocialLoginCallback.ButtonText_TryAgain"),
                                    to: `${PageRoute.INSTANT_APPLY.INDEX}/${
                                        PageRoute.INSTANT_APPLY.POST_INTERVIEW
                                    }/?email=${encodeURIComponent(options.email)}`,
                                },
                            });
                        }
                        return;
                    }
                    toast.error<string>(t(`AppMessages.${response.errorMessage}`));
                } else {
                    dispatch(setSession(response.payload));
                    if (options?.invitation && options?.action === PageState.INVITATION_SIGN_IN) {
                        const urlSearchParams = new URLSearchParams({
                            interviewId: options.invitation.interviewId,
                            token: response.payload.access_token,
                            displayName: options.invitation.invitee.displayName,
                        });
                        navigate(`${PageRoute.PRE_INTERVIEW}?${urlSearchParams}`, {
                            replace: true,
                        });
                        return;
                    }

                    if (options?.linkPassword) {
                        navigate(PageRoute.PROFILE, { replace: true });
                        return;
                    }

                    if (
                        options?.redirectUrl &&
                        options?.email &&
                        options?.action === PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN
                    ) {
                        const redirectUrl = new URL(
                            `${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.POST_INTERVIEW}`,
                            window.location.origin,
                        );

                        redirectUrl.searchParams.append("email", options?.email);

                        const interviewUrl = new URL(options.redirectUrl);
                        interviewUrl.searchParams.set("redirectUrl", redirectUrl.toString());
                        interviewUrl.searchParams.set("token", response.payload.access_token);
                        window.location.replace(interviewUrl.toString());
                        return;
                    }

                    if (options?.redirectUrl) {
                        navigate(options.redirectUrl, { replace: true });
                        return;
                    }

                    if (
                        postInvitationStates.find((x) => x === options?.action) ||
                        postJobApplyStates.find((x) => x === options?.action)
                    ) {
                        navigate(`${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`, { replace: true });
                        return;
                    } else if (response.payload?.action === TokenAction.SIGNUP) {
                        navigate(PageRoute.DASHBOARD, { replace: true, state: "new-user" });
                        return;
                    }

                    navigate(PageRoute.DASHBOARD, { replace: true });
                    return;
                }
            } catch (error) {
                toast.error<string>((error as Error).message);
            }
            navigate(PageRoute.ROOT, { replace: true });
        },
        [dispatch, federatedSignin, navigate, t],
    );

    useEffect(() => {
        if (!shouldExecute.current) {
            return;
        }
        shouldExecute.current = false;

        const code = searchParams.get("code");
        const action = searchParams.get("state");

        if (action && code) {
            const decodedState = decodeStateParam(action);

            switch (decodedState.action) {
                case PageState.SIGN_UP:
                case PageState.SIGN_IN:
                    exchangeGoogleIdentity(code, {
                        redirectUrl: decodedState.redirectUrl,
                        email: decodedState.email,
                    });
                    break;
                case PageState.INVITATION_POST_INTERVIEW_SIGN_IN:
                case PageState.INVITATION_POST_INTERVIEW_SIGN_UP:
                    exchangeGoogleIdentity(code, {
                        redirectUrl: decodedState.redirectUrl,
                        invitation: decodedState.invitation || undefined,
                        action: decodedState.action,
                        token: decodedState.token,
                    });
                    break;
                case PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN:
                case PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP:
                case PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN:
                    exchangeGoogleIdentity(code, {
                        redirectUrl: decodedState.redirectUrl,
                        email: decodedState.email,
                        action: decodedState.action,
                    });
                    break;
                case PageState.INVITATION_SIGN_IN:
                    if (decodedState.token) {
                        exchangeGoogleIdentity(code, {
                            invitation: decodedState.invitation || undefined,
                            token: decodedState.token,
                            redirectUrl: decodedState.redirectUrl,
                            action: decodedState.action,
                        });
                    } else {
                        setShowAlert({
                            title: t("AppMessages.server-error"),
                            subtitle: t("AppMessages.invalid-invitation"),
                            type: "warning",
                        });
                    }
                    break;
                case PageState.LINK_PASSWORD:
                    exchangeGoogleIdentity(code, {
                        linkPassword: true,
                        redirectUrl: decodedState.redirectUrl,
                    });
                    break;
                default:
                    break;
            }
        } else {
            navigate(PageRoute.ROOT, { replace: true });
        }
    }, [navigate, t, exchangeGoogleIdentity, searchParams]);

    if (showAlert.type === "warning" || showAlert.type === "danger") {
        return <LargeAlert {...showAlert} />;
    }

    return <TransitionPage title={showAlert.title} subtitle={showAlert.subtitle} />;
};

export default GoogleCallback;
