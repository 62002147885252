import { PageRoute } from "../constants";
import { deserialize, serialize } from "../hooks/useDataSerializer";
import { LoginStateWithAction } from "../types/authentication";

const encodeParam = (payload: unknown): string => {
    const encodedObject = serialize(payload);
    return encodeURIComponent(encodedObject);
};

const decodeStateParam = (payload: string): LoginStateWithAction => {
    return deserialize<LoginStateWithAction>(decodeURIComponent(payload));
};

const generateInterviewUrlRaw = (interviewId: string, redirectLocalPath: string, accessToken?: string | null) => {
    if (!import.meta.env.VITE_INTERVIEW_URL) throw new Error("Missing base url for interviews");

    const interviewUrl = new URL(`${import.meta.env.VITE_INTERVIEW_URL}/${interviewId}`);
    if (accessToken) interviewUrl.searchParams.append("token", accessToken);

    const redirectUrl = `${window.location.origin}${redirectLocalPath}`;
    interviewUrl.searchParams.append("redirectUrl", redirectUrl);

    return interviewUrl.toString();
    //return `${interviewUrl}/${interviewId}?token=${accessToken}&redirectUrl=${redirectUrl}`;
};

const generateGoogleSignInUrl = (state: string) => {
    const googleSignInUrl = new URL("https://accounts.google.com/o/oauth2/auth");
    googleSignInUrl.searchParams.append(
        "scope",
        "https://www.googleapis.com/auth/userinfo.email" + " https://www.googleapis.com/auth/userinfo.profile",
    );

    googleSignInUrl.searchParams.append("response_type", "code");
    googleSignInUrl.searchParams.append("redirect_uri", window.location.origin + PageRoute.GOOGLE_CALLBACK);
    googleSignInUrl.searchParams.append("client_id", import.meta.env.VITE_GOOGLE_APP_CLIENTID);
    googleSignInUrl.searchParams.append("state", state);
    return googleSignInUrl;
};

export { decodeStateParam, encodeParam, generateGoogleSignInUrl, generateInterviewUrlRaw };
