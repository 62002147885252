import { useState } from "react";
import { useTranslation } from "react-i18next";
import TransitionPage from "../../../pages/transition/TransitionPage";
import { HorizontalLine, Modal, Text } from "../../elements";

interface IframeModalProps {
    isOpen: boolean;
    close: () => void;
    interviewId: string;
    isLegacyInterview: boolean;
}

const IframeModal = ({ isOpen, close, interviewId, isLegacyInterview = false }: IframeModalProps) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    const src = isLegacyInterview
        ? `${import.meta.env.VITE_INTERVIEW_URL}/viewer?interview_id=${interviewId}`
        : `${import.meta.env.VITE_INTERVIEW_URL}/preview/${interviewId}`;

    console.info("IframeModal", { interviewId, src });

    return (
        <Modal isOpen={isOpen} close={close} className="iframe-modal-popup" large>
            <div>
                <Text size="large" weight="semibold">
                    {t("Interview.ScriptFromInterview.Title")}
                </Text>
                <HorizontalLine weight="semi-dark" />
            </div>
            {loading ? (
                <TransitionPage
                    title={t("Interview.ScriptFromInterview.Loading_Title")}
                    subtitle={t("Interview.ScriptFromInterview.Loading_Subtitle")}
                />
            ) : (
                <div />
            )}
            <iframe src={src} tabIndex={1} title="Interview script" onLoad={() => setLoading(false)} />
        </Modal>
    );
};

export default IframeModal;
