import { useTranslation } from "react-i18next";
import Job from "../../../types/job";
import { generateLiteralDay } from "../../../utils/dateUtilities";
import { StartInterviewButton } from "../../common";
import { Text } from "../../elements";
import SkeletonLoader from "../../elements/SkeletonLoader";

interface ToDoInterviewProps {
    applicationId?: string;
    interviewId?: string;
    job?: Job;
    createdAt?: Date;
    loading?: boolean;
}

const ToDoInterview = ({
    applicationId = "",
    interviewId = "",

    job = {
        id: "",
        company: "",
        title: "",
        candidateApp: false,
    },

    createdAt,
    loading = false,
}: ToDoInterviewProps) => {
    const { t } = useTranslation();

    return (
        <>
            {!loading && applicationId && interviewId && (
                <div className="section-body mt-5">
                    <div className="left-body-section">
                        <Text weight="medium" block>
                            {job?.company}
                        </Text>
                        <Text weight="light" block className="mt-2">
                            {job?.title}
                        </Text>
                    </div>
                    <div className="right-body-section">
                        <Text className="created-at" block>
                            {generateLiteralDay(createdAt ?? null, t("Common.MyInterviews.ApplicationDate_Invited"), t)}
                        </Text>
                        <StartInterviewButton
                            btnLabel={t("Dashboard.MyCareer.ButtonText_StartInterview")}
                            hidden={loading}
                            applicationId={applicationId}
                            interviewId={interviewId}
                            oldInterviewFlow={job?.candidateApp !== true}
                        />
                    </div>
                </div>
            )}
            {loading && (
                <div className="col-span-full pt-3">
                    <SkeletonLoader count={2} />
                </div>
            )}
        </>
    );
};

export default ToDoInterview;
