interface HorizontalLineProps {
    weight: "light" | "regular" | "medium" | "semi-dark" | "dark";
    className?: string;
}

const HorizontalLine = ({ weight, className = "" }: HorizontalLineProps) => (
    <div className={`horizontal-line ${weight} ${className}`} />
);

export default HorizontalLine;
