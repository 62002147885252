import { ArrowRight } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router";
import { Button, Heading, Text } from "../../components/elements";
import Link from "../../components/elements/Link";
import Header from "../../components/header/Header";
import TipBox from "../../components/preInterviewPage/TipBox";
import "./PreInterviewPage.scss";
import { generateInterviewUrlRaw } from "../../utils/urlUtility";
import { PageRoute, PageState } from "../../constants";

const PreInterviewPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [candidateName, setCandidateName] = useState("");
    const [interviewUrl, setInterviewUrl] = useState("");

    console.log("PreInterviewPage", { state: location.state });

    useEffect(() => {
        const interviewId = searchParams.get("interviewId");
        const redirectUrl = searchParams.get("redirectUrl");
        const displayName = searchParams.get("displayName");
        const token = searchParams.get("token");
        if (interviewId) {
            setCandidateName(displayName ?? "");
            setInterviewUrl(
                generateInterviewUrlRaw(
                    interviewId,
                    redirectUrl ?? `${PageRoute.DASHBOARD}?state=${PageState.INTERVIEW_COMPLETED}`,
                    token,
                ),
            );
        } else if (location.state) {
            const details = location.state;
            if (details.displayName) {
                setCandidateName(details.displayName);
            }
            if (details.interviewUrl) {
                setInterviewUrl(details.interviewUrl);
            }
        } else {
            console.log("PreInterview page lost it");
            navigate("/", { replace: true });
        }
    }, [location.state, navigate, searchParams]);

    return (
        <main>
            <Header />
            <div className="pre-interview-page-wrapper">
                <div className="content-wrapper">
                    <div className="header-section">
                        <Heading
                            level="h2"
                            light
                            className="text-2xl md:text-4xl"
                        >{`${t("PreInterviewPage.Title_Hello")} ${candidateName}`}</Heading>
                        <Heading level="h5" className="text-base md:text-xl max-w-xs md:max-w-none mt-2 mx-auto">
                            {t("PreInterviewPage.Subtitle")}
                        </Heading>
                    </div>
                    <TipBox />
                    <div className="ready-to-start-container">
                        <Text weight="semibold" block className="text-base">
                            {t("PreInterviewPage.ReadyToGetStarted")}
                        </Text>
                        <Button link to={interviewUrl} className="w-full apply-btn">
                            <span className="mr-2">{t("PreInterviewPage.ButtonText_StartInterviewNow")}</span>
                            <span>
                                <ArrowRight />
                            </span>
                        </Button>
                        <div className="flex flex-col items-center">
                            <Text className="text-xs">{`${t("PreInterviewPage.PrivacyPolicyText")} `}</Text>
                            <Link to="https://www.hubert.ai/candidate-privacy-policy">
                                <Text weight="semibold" className="text-xs">
                                    {t("PreInterviewPage.PrivacyPolicyLink")}
                                </Text>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default PreInterviewPage;
