import { type Configuration } from "rollbar";

export const rollbarConfig: Configuration = {
    enabled: import.meta.env.PROD,
    accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN,
    environment: import.meta.env.MODE,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
        client: {
            javascript: {
                code_version: import.meta.env.VITE_VERSION,
                source_map_enabled: true,
                guess_uncaught_frames: true,
            },
        },
        server: { root: "../../" },
    },
};
