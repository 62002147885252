import { ReactNode } from "react";
import SkeletonLoader from "./SkeletonLoader";

interface HeadingProps {
    level: "h1" | "h2" | "h3" | "h4" | "h5";
    light?: boolean;
    className?: string;
    children?: ReactNode;
    loading?: boolean;
}

const Heading = ({ level, light = false, className = "", children, loading = false }: HeadingProps) => {
    const HeadingTag = level;

    return (
        <HeadingTag className={`${className} ${light && "light"}`}>
            {loading ? <SkeletonLoader count={1} /> : children}
        </HeadingTag>
    );
};

export default Heading;
