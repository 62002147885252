import useBreakpoint from "use-breakpoint";
import { PageRoute } from "../../constants";
import { Content } from "../../types/content";
import { BREAKPOINTS, Breakpoint } from "../../utils/constants/breakpoints";
import SkeletonLoader from "../elements/SkeletonLoader";
import ArticleCard from "./ArticleCard";

interface ArticleCardListProps {
    articles: Content[] | null;
    wrapperClass?: "grid-items-wrapper" | "article-card-wrapper";
    loading: boolean;
}

function getSkeletonCountToShow(breakpoint: Breakpoint) {
    if (breakpoint === "sm") {
        return 2;
    }

    if (breakpoint === "md") {
        return 2;
    }
    return 4;
}

const ArticleCardList = ({ articles, wrapperClass = "article-card-wrapper", loading }: ArticleCardListProps) => {
    const { breakpoint } = useBreakpoint(BREAKPOINTS, "lg");
    const noOfSkeletonToShow = getSkeletonCountToShow(breakpoint);

    if (loading) {
        const rows = [];
        for (let index = 0; index < noOfSkeletonToShow; index += 1) {
            const loader = (
                <SkeletonLoader
                    containerClassName="custom-article-card"
                    className={wrapperClass}
                    key={`skeleton-card-${index}`}
                />
            );
            rows.push(loader);
        }
        return <div className={`${wrapperClass} skeleton-wrapper`}>{rows}</div>;
    }

    return (
        <div className={wrapperClass}>
            {articles &&
                articles.map((article) => (
                    <ArticleCard
                        article={article}
                        to={`${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${article.id}`}
                        key={article.title}
                    />
                ))}
        </div>
    );
};

export default ArticleCardList;
