import { useTranslation } from "react-i18next";
import HubertLogo from "../../assets/images/hubert-logo-mini.svg";
import { Heading, Text } from "../elements";

const StillHaveQuestion = () => {
    const { t } = useTranslation();

    return (
        <div className="still-have-question-wrapper">
            <img src={HubertLogo} className="hubert-logo" />
            <Heading level="h4" className="mt-5 mb-2">
                {t("HelpCenter.StillHaveQuestion.Title")}
            </Heading>
            <Text block className="mb-1.5">
                {t("HelpCenter.StillHaveQuestion.Description")}
            </Text>
            <Text weight="light" block>
                {t("HelpCenter.StillHaveQuestion.DropUsAEmail")}
            </Text>
        </div>
    );
};

export default StillHaveQuestion;
