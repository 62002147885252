import classNames from "classnames";
import { X } from "lucide-react";
import { ComponentProps, ReactNode, useEffect } from "react";

interface ModalProps extends ComponentProps<"div"> {
    isOpen?: boolean;
    close: () => void;
    large?: boolean;
    children?: ReactNode;
}

const Modal = ({ isOpen = false, close, large = false, className = "", children }: ModalProps) => {
    useEffect(() => {
        const bodyTag = document.querySelector("body");

        if (isOpen) {
            bodyTag?.classList.add("no-scroll");
        }

        return () => {
            bodyTag?.classList.remove("no-scroll");
        };
    }, [isOpen]);

    return (
        <div className={classNames("modal-wrapper", large && "large", className)}>
            <X className="close-icon" onClick={close} />
            {children}
        </div>
    );
};

export default Modal;
