import { useTranslation } from "react-i18next";
import InfoIcon from "../../../assets/images/info-icon.svg";
import { Button, Modal, Text } from "../../elements";

interface OldInterviewRedirectModalProps {
    close: () => void;
    applicationId: string;
    interviewId: string;
}

const OldInterviewRedirectModal = ({ close, interviewId }: OldInterviewRedirectModalProps) => {
    const { t } = useTranslation();

    const handleRedirection = () => {
        const url = `${import.meta.env.VITE_INTERVIEW_URL}/${interviewId}`;
        window.open(url, "_blank")?.focus();
    };

    return (
        <Modal close={close} className="old-redirection-modal-wrapper">
            <div className="head-section">
                <img src={InfoIcon} className="info-icon" />
            </div>
            <div className="body-section">
                <Text block>{t("OldInterviewRedirectionModal.DescriptionLine1")}</Text>
                <Text block>{t("OldInterviewRedirectionModal.DescriptionLine2")}</Text>
            </div>
            <div className="button-section">
                <Button
                    onClick={() => {
                        handleRedirection();
                        close();
                    }}
                >
                    {t("OldInterviewRedirectionModal.ButtonText_TakeMeToTheInterview")}
                </Button>
            </div>
        </Modal>
    );
};

export default OldInterviewRedirectModal;
