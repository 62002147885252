import { Navigate, Outlet, useLocation } from "react-router";
import { isLogged } from "./behavior/security/auth.service";
import { PageState } from "./constants";

const ProtectedRoute = () => {
    const location = useLocation();
    if (!isLogged()) {
        const redirectUrl = encodeURIComponent(location.pathname);
        return <Navigate to={`/?state=${PageState.SESSION_EXPIRED}&redirectUrl=${redirectUrl}`} />;
    }
    return <Outlet />;
};

export default ProtectedRoute;
