import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router";
import { isTokenExpiring } from "../../behavior/security/auth.service";
import { PageRoute } from "../../constants";
import { getPostInterviewUrl, useLoginParams, useReuseToken } from "../../pages/login/RealTimeScreening/Login";
import TransitionPage from "../../pages/transition/TransitionPage";
import { useMyHubertSession } from "./useMyHubertSession";

export interface PreInterviewCandidate {
    firstName?: string;
    lastName?: string;
    email: string;
    phone?: string;
    providerData?: UserProviderData[];
}

interface UserProviderData {
    providerId: string;
    email: string;
}

const PreInstantApply = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const params = useLoginParams(searchParams);
    const { sessionToken } = useMyHubertSession();
    const { appendReuseParams } = useReuseToken();

    const { candidate, redirectUrl } = params;
    const hasSession = sessionToken && candidate && redirectUrl;
    const wrongUser = hasSession && sessionToken.user && sessionToken.user.email !== candidate.email;
    const action = !hasSession ? "login" : wrongUser || isTokenExpiring(sessionToken.rawToken) ? "logout" : "redirect";

    const actions = useMemo(() => {
        const { job, candidate, redirectUrl } = params;
        return {
            login() {
                navigate(
                    `${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.LOGIN}?${searchParams.toString()}`,
                );
            },
            logout() {
                const currentUrl = `${PageRoute.INSTANT_APPLY.INDEX}/${PageRoute.INSTANT_APPLY.PRE_INTERVIEW}?${searchParams.toString()}`;
                navigate(PageRoute.TRANSITION_PAGE, { state: { redirectUrl: currentUrl, logout: wrongUser } });
            },
            async redirect() {
                if (!redirectUrl || !candidate || !sessionToken?.rawToken)
                    throw new Error("Missing required parameter");

                const next = new URL(redirectUrl);
                next.searchParams.append("token", sessionToken.rawToken);
                await appendReuseParams(job!.id, next);

                const postInterviewUrl = getPostInterviewUrl();
                postInterviewUrl.searchParams.append("email", candidate.email);
                next.searchParams.append("redirectUrl", postInterviewUrl.toString());

                window.location.replace(next);
            },
        };
    }, [sessionToken, params, appendReuseParams, navigate, wrongUser, searchParams]);

    useEffect(() => {
        actions[action]();
    }, [action, actions]);

    return <TransitionPage />;
};

export default PreInstantApply;
