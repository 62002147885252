import { useQuery } from "@apollo/client";
import { Link } from "react-router";
import ArrowIcon from "../../assets/images/article-link-card-arrow-icon.png";
import BriefcaseIcon from "../../assets/images/article-link-card-briefcase-icon.png";
import { GET_ARTICLE_CARD_INFO } from "../../behavior/queries/content.query";
import { PageRoute } from "../../constants";
import { ContentCollection } from "../../types/content";
import { Heading, HorizontalLine, Image } from "../elements";

const ArticleLinkCard = () => {
    const { data, loading } = useQuery<{ contentCollection: ContentCollection }>(GET_ARTICLE_CARD_INFO, {
        variables: {
            candidateFilterInput: {
                tags: ["Dashboard Interview Guide"],
                optionalTags: [],
                pagination: {
                    page: 0,
                    limit: 1,
                },
            },
        },
    });

    return (
        <Link
            className="article-link-card-wrapper"
            to={
                !loading && data?.contentCollection.contents.length
                    ? `${PageRoute.LEARN_WITH_HUBERT_ARTICLE}/${data?.contentCollection.contents[0].id}`
                    : PageRoute.LEARN_WITH_HUBERT
            }
        >
            <div className="head-section">
                <Image src={BriefcaseIcon} alt="Briefcase icon" className="briefcase-icon" />
                {!loading && data?.contentCollection.contents.length && (
                    <Heading level="h4">{data?.contentCollection.contents[0].primaryTag}</Heading>
                )}
            </div>
            <HorizontalLine weight="semi-dark" className="mb-6" />
            <div className="body-section">
                {!loading && data?.contentCollection.contents.length && (
                    <Heading level="h4">{data?.contentCollection.contents[0].title}</Heading>
                )}
                <div className="arrow-icon-wrapper">
                    <Image src={ArrowIcon} alt="Arrow icon" className="arrow-icon" />
                </div>
            </div>
        </Link>
    );
};

export default ArticleLinkCard;
