import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import MiniLogoImage from "../../assets/images/hubert-logo-mini.png";
import BgImage from "../../assets/images/login-bg-image.png";
import { Heading, LargeAlert } from "../../components/elements";
import { LargeAlertProps } from "../../components/elements/LargeAlert";
import Header from "../../components/header/Header";
import LoginForm from "../../components/login/LoginForm";
import SocialMediaLogin from "../../components/login/SocialMediaLogin";
import { PageState, TPageState } from "../../constants";
import "./Login.scss";

const Login = () => {
    const { t } = useTranslation();
    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: "",
        subtitle: "",
        type: "info",
    });
    const [pageState, setPageState] = useState<TPageState>(PageState.SIGN_IN);
    const [email, setEmail] = useState("");
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const state = searchParams.get("state") as TPageState;
        const userEmail = searchParams.get("email");
        if (state) setPageState(state);
        if (userEmail) setEmail(userEmail);
    }, [searchParams]);

    return (
        <>
            <Header type="Login" />
            <div className="login-wrapper">
                <div className="left-section">
                    <Heading level="h2">{t("Login.MainTitle")}</Heading>
                    <Heading level="h4" light>
                        {t("Login.Subtitle")}
                    </Heading>
                    {!showAlert.title && <img className="bg-image" src={BgImage} alt="Login background" />}
                </div>
                <div className="right-section">
                    <img className="hubert-logo-mini" src={MiniLogoImage} alt="Hubert logo mini" />
                    <Heading level="h4">{t("Login.LoginTitle")}</Heading>
                    {showAlert.title ? (
                        <LargeAlert title={showAlert.title} subtitle={showAlert.subtitle} type={showAlert.type} />
                    ) : (
                        <div>
                            <LoginForm setShowAlert={setShowAlert} preLoadedEmail={email} state={pageState} />
                            <div>
                                <div className="or-section">
                                    <div className="or-seperator-line" />
                                    <div className="or-text">{t("Login.OrSeparator")}</div>
                                </div>
                                <SocialMediaLogin state={pageState} preLoadedEmail={email} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Login;
