import { gql } from "@apollo/client";

export const SEND_BENEFITS_EMAIL = gql`
    mutation SEND_BENEFITS_EMAIL($token: String, $language: String) {
        sendBenefitsEmail(token: $token, language: $language)
    }
`;

export const UNSUBSCRIBE_REMINDER = gql`
    mutation UNSUBSCRIBE_REMINDER($unsubscribeInput: UnsubscribeInput) {
        unsubscribeReminder(unsubscribeInput: $unsubscribeInput)
    }
`;

export const OPENED_DASHBOARD = gql`
    mutation OPENED_DASHBOARD($applicationId: String, $token: String) {
        openedDashboard(applicationId: $applicationId, token: $token)
    }
`;
