import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { toast } from "react-toastify";
import MiniLogoImage from "../../../assets/images/hubert-logo-mini.png";
import { Heading, LargeAlert } from "../../../components/elements";
import { LargeAlertProps } from "../../../components/elements/LargeAlert";
import Header from "../../../components/header/Header";
import CompletedAlert from "../../../components/interview/CompletedAlert";
import LoginForm from "../../../components/login/LoginForm";
import SocialMediaLogin from "../../../components/login/SocialMediaLogin";
import { BenefitsBox } from "../../../components/registration";
import { ProviderId, TPageState } from "../../../constants";
import useDataSerializer from "../../../hooks/useDataSerializer";

interface Candidate {
    firstName: string;
    lastName: string;
    email: string;
    phone?: string;
    isHubertUser: boolean;
    providerData: UserProviderData[];
}

interface UserProviderData {
    providerId: string;
    email: string;
}

const RealTimeScreeningPostInterviewLogin = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { deserialize } = useDataSerializer();

    const [showAlert, setShowAlert] = useState<LargeAlertProps>({
        title: "",
        subtitle: "",
        type: "info",
    });

    const pageState = useMemo(() => searchParams.get("state") as TPageState, [searchParams]);

    const job = useMemo(() => {
        const jobParam = searchParams.get("job");
        if (jobParam) {
            return deserialize<{ id: string; title: string }>(jobParam);
        }
    }, [deserialize, searchParams]);

    const candidate = useMemo(() => {
        const candidateParam = searchParams.get("candidate");
        if (candidateParam) {
            return deserialize<Candidate>(candidateParam);
        }
    }, [deserialize, searchParams]);

    const checkSignInProvider = (selectedProvider: string): boolean => {
        const providerData = candidate?.providerData;
        if (providerData) {
            if (providerData.length > 0) {
                const selected = providerData.find((x) => x.providerId === selectedProvider);
                if (selected) {
                    return true;
                }

                switch (providerData[0].providerId) {
                    case ProviderId.PASSWORD:
                        toast.info<string>("Please sign in using Password.");
                        break;
                    case ProviderId.GOOGLE:
                        toast.info<string>("Please sign in using Google.");
                        break;
                    default:
                        toast.info<string>("Unsupported provider");
                        break;
                }
                return false;
            }
        }
        toast.info<string>("Unsupported provider");
        return false;
    };

    return (
        <>
            <Header />
            <div className="login-interview-completed-invite-email-wrapper">
                <CompletedAlert />
                <div className="login-invite-email-wrapper">
                    <div className="left-section">
                        <Heading level="h2" light>
                            {job?.title}
                        </Heading>
                        <Heading level="h4">{t("LoginInviteEmail.Subtitle")}</Heading>
                        <BenefitsBox />
                    </div>
                    <div className="right-section">
                        <img className="hubert-logo-mini" src={MiniLogoImage} alt="Hubert logo mini" />
                        <Heading level="h4">{t("Login.LoginTitle")}</Heading>
                        {showAlert.title ? (
                            <LargeAlert title={showAlert.title} subtitle={showAlert.subtitle} type={showAlert.type} />
                        ) : (
                            <div>
                                <LoginForm
                                    setShowAlert={setShowAlert}
                                    preLoadedEmail={candidate?.email}
                                    state={pageState}
                                    validateProvider={checkSignInProvider}
                                />
                                <div className="or-section">
                                    <div className="or-seperator-line" />
                                    <div className="or-text">{t("Login.OrSeparator")}</div>
                                </div>
                                <SocialMediaLogin
                                    state={pageState}
                                    validateProvider={checkSignInProvider}
                                    preLoadedEmail={candidate?.email}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default RealTimeScreeningPostInterviewLogin;
