import { useTranslation } from "react-i18next";
import RoundIcon from "../../../assets/images/round-icon.png";
import { Image, Text } from "../../elements";

const NoOngoingInterviews = () => {
    const { t } = useTranslation();

    return (
        <div className="section-body no-ongoing-interviews mt-4">
            <Image src={RoundIcon} alt="Round icon" />
            <div>
                <Text weight="semibold" block>
                    {t("Dashboard.MyCareer.NoOngoingInterviews_Title")}
                </Text>
                <Text size="small" block>
                    {t("Dashboard.MyCareer.NoOngoingInterviews_Subtitle")}
                </Text>
            </div>
        </div>
    );
};

export default NoOngoingInterviews;
