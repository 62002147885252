import { Outlet } from "react-router";

const CenteredLayout = () => {
    return (
        <main className="container">
            <Outlet />
        </main>
    );
};

export default CenteredLayout;
