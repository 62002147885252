import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { unsubscribeFormSchema } from "../../utils/validationSchemas";
import { Button, Text } from "../elements";
import { Radio, Textbox } from "../forms";
import { getReasonsOptions, REASONS } from "./constants";

interface ReasonsWrapperProps {
    handleOnSubmit: (props: typeof initialValues) => Promise<void>;
}

export const initialValues = {
    reason: REASONS.FOUND_NEW_JOB,
    yourReason: "",
};

const ReasonsWrapper = ({ handleOnSubmit }: ReasonsWrapperProps) => {
    const { t } = useTranslation();
    const reasons = getReasonsOptions();

    const { values, errors, touched, handleSubmit, handleChange, handleBlur } = useFormik({
        initialValues,
        validationSchema: unsubscribeFormSchema,
        onSubmit: handleOnSubmit,
    });

    return (
        <div className="reasons-wrapper">
            <Text block>{t("UnsubscribeFromEmails.SelectTheReason")}</Text>
            <form onSubmit={handleSubmit}>
                <div className="radios-wrapper">
                    {reasons.map((reason) => (
                        <Radio
                            key={reason.label}
                            name="reason"
                            label={reason.label}
                            value={reason.value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            defaultValue={values.reason}
                        />
                    ))}
                    {values.reason === REASONS.OTHER && (
                        <div className="textbox-wrapper">
                            <Textbox
                                name="yourReason"
                                placeholder={t("UnsubscribeFromEmails.Placeholder_YourReason")}
                                value={values.yourReason}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                error={errors.yourReason}
                                touched={touched.yourReason}
                            />
                        </div>
                    )}
                </div>
                <div>
                    <Button submit>{t("UnsubscribeFromEmails.ButtonText_Unsubscribe")}</Button>
                </div>
            </form>
        </div>
    );
};

export default ReasonsWrapper;
