import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import GoogleIcon from "../../assets/images/google-logo.png";
import { PageState, ProviderId, TPageState } from "../../constants";
import { LoginStateWithAction } from "../../types/authentication";
import { Invitation } from "../../types/invitation";
import { encodeParam, generateGoogleSignInUrl } from "../../utils/urlUtility";
import { Button } from "../elements";

interface SocialMediaLoginProps {
    state?: TPageState | null;
    validateProvider?: (selectedProvider: string) => boolean;
    invitation?: Invitation | null;
    preLoadedEmail?: string | null;
}

const SocialMediaLogin = ({
    state,
    validateProvider = () => true,
    invitation,
    preLoadedEmail,
}: SocialMediaLoginProps) => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const postInvitationStates = [
        PageState.INVITATION_POST_INTERVIEW_SIGN_UP,
        PageState.INVITATION_POST_INTERVIEW_SIGN_IN,
    ];
    const jobApplyStates = [
        PageState.JOB_APPLY_POST_INTERVIEW_SIGN_UP,
        PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN,
        PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN,
    ];

    const signInStates = [
        PageState.INVITATION_SIGN_IN,
        PageState.INVITATION_POST_INTERVIEW_SIGN_IN,
        PageState.JOB_APPLY_POST_INTERVIEW_SIGN_IN,
        PageState.JOB_APPLY_PRE_INTERVIEW_SIGN_IN,
    ];

    const extractToken = () => {
        return searchParams.get("token");
    };

    const appendRedirectUri = (previousState: LoginStateWithAction) => {
        const hasRedirectUrl = searchParams.has("redirectUrl");
        const redirectUrl = searchParams.get("redirectUrl");

        if (hasRedirectUrl && redirectUrl) {
            return { ...previousState, redirectUrl };
        }

        return previousState;
    };

    const buildLoginState = () => {
        const token = extractToken();
        let loginState: LoginStateWithAction = { action: PageState.SIGN_IN };

        if (state === PageState.INVITATION_SIGN_IN) {
            loginState = {
                action: PageState.INVITATION_SIGN_IN,
                invitation,
            };
            if (token) loginState.token = token;
        } else if (jobApplyStates.find((x) => x === state)) {
            if (preLoadedEmail)
                loginState = {
                    action: state as TPageState,
                    email: preLoadedEmail,
                };
        } else if (postInvitationStates.find((x) => x === state)) {
            loginState = {
                action: state as TPageState,
                invitation,
            };
            if (token) loginState.token = token;
        }
        return loginState;
    };

    const googleSignIn = async () => {
        if (validateProvider && validateProvider(ProviderId.GOOGLE)) {
            const loginState = appendRedirectUri(buildLoginState());
            const encodedGoogleSignInState = encodeParam(loginState);
            const googleSignInUrl = generateGoogleSignInUrl(encodedGoogleSignInState);

            if (signInStates.find((x) => x === state) && preLoadedEmail) {
                googleSignInUrl.searchParams.append("login_hint", preLoadedEmail);
            }
            window.location.replace(googleSignInUrl);
        }
    };

    return (
        <div className="social-media-login-wrapper">
            <Button secondary fullWidth className="mb-4" imgIcon={GoogleIcon} onClick={googleSignIn}>
                {t("Login.ButtonText_ContinueWithGoogle")}
            </Button>
        </div>
    );
};

export default SocialMediaLogin;
